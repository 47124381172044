import { mapState, mapActions, mapGetters } from 'vuex'
import UploadFinishPhoto from '@/views/prevention/components/UploadFinishPhoto'
import ViewFinishPhoto from '@/views/prevention/components/ViewFinishPhoto'
import ChangeDate from '@/components/modal/ChangeDate'

export default {
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isPrevention']),
    stepsLogic () {
      const isSpecialist = [
        'PREV_INSPECTOR'
      ].includes(this.isPrevention)
      switch (this.violationData.status) {
        case 'CREATING_DRAFT': {
          return {
            isView: false,
            colorStatus: 'titanic--text',
            actions: isSpecialist ? [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              },
              {
                colorAction: 'success',
                textAction: 'В работу',
                funcAction: async () => {
                  if (await this.updateItem()) {
                    await this.changeStatusViolation({
                      decision: 'APPROVE'
                    })
                  }
                }
              },
              {
                funcAction: async () => {
                  if (await this.updateItem()) {
                    const confirm = await window.confirm('Действительно хотите отменить нарушений?')
                    confirm && await this.changeStatusViolation({ decision: 'CANCEL' })
                  }
                },
                colorAction: 'error',
                textAction: 'Отменить'
              }
            ] : [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              }
            ]
          }
        }
        case 'PREVENTIVE_ACTIVITIES':
          return {
            isView: false,
            colorStatus: 'matrix--text',
            actions: isSpecialist ? [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              },
              {
                colorAction: 'primary',
                textAction: 'На проверку',
                funcAction: async () => {
                  await this.changeStatusViolation({
                    decision: 'APPROVE'
                  })
                }
              },
              {
                colorAction: 'primary',
                textAction: 'Изменить срок исполнения',
                funcAction: async () => {
                  const newDate = await this.$modal(ChangeDate)
                  this.model.executionTerm = newDate || this.model.executionTerm
                }
              }
            ] : [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              }
            ]
          }
        case 'EXPIRED': {
          return {
            isView: false,
            colorStatus: 'matrix--text',
            actions: isSpecialist ? [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              },
              {
                colorAction: 'primary',
                textAction: 'На проверку',
                funcAction: async () => {
                  await this.changeStatusViolation({
                    decision: 'APPROVE'
                  })
                }
              }
            ] : [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              }
            ]
          }
        }
        case 'INSPECTING_ELIMINATION_VIOLATION':
          return {
            isView: false,
            colorStatus: 'titanic--text',
            actions: isSpecialist ? [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              },
              {
                colorAction: 'primary',
                textAction: 'Устранено',
                funcAction: async () => {
                  await this.updateItem()
                  await this.changeStatusViolation({
                    decision: 'ELIMINATED'
                  })
                }
              },
              {
                colorAction: 'error',
                textAction: 'Отправить в муниципальный контроль',
                funcAction: async () => {
                  await this.updateItem()
                  await this.changeStatusViolation({
                    decision: 'DIRECTED_TO_MUNICIPAL_CONTROL'
                  })
                }
              }
            ] : [
              {
                colorAction: 'primary',
                textAction: 'Сохранить',
                funcAction: this.updateItem
              }
            ],
            components: [
              UploadFinishPhoto
            ]
          }
        case 'ELIMINATED':
        case 'DIRECTED_TO_MUNICIPAL_CONTROL':
          return {
            isView: true,
            colorStatus: 'titanic--text',
            components: [
              ViewFinishPhoto
            ],
            actions: [
              {
                colorAction: 'success',
                textAction: 'Распечатать отчет',
                funcAction: () => {
                  this.printReport()
                }
              }
            ]
          }
        default:
          return {
            isView: true,
            colorStatus: 'titanic--text'
          }
      }
    }
  },
  methods: {
    ...mapActions('violation', ['changeStatusViolation'])
  }
}
