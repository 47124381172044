<template>
  <section v-if="!isLoadData">
    <div class="row">
      <div class="col-md-12-12 d-flex align-center">
        <rir-icon icon="calendar" class="mr-xs-2"/>
        <span class="mr-xs-4 kleodora titanic--text biqe">{{ violationData.createdAt | dateTextMonth }}</span>
        <rir-icon icon="clock"  class="mr-xs-2"/>
        <span class="kleodora titanic--text biqe">{{ violationData.createdAt | time }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 d-flex align-center">
        <span
          :class="[`${statusViolation.color}--text`]"
          class="kleodora">{{ statusViolation.name }}</span>
      </div>
      <div class="col-md-12-12 d-flex align-center">
        <rir-select
          v-if="isDepartmentHead"
          label="Инспектор"
          :items="profilesRoad"
          id-value="id"
          text-value="fullName"
          v-model="model.executorId"
        />
        <template v-else>
          <rir-icon icon="avatar" class="mr-xs-2"/>
          <span class="kleodora titanic--text biqe">{{ violationData.executorName }}</span>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8-12">
        <h2 class="melia mb-xs-5">Информация о нарушении</h2>
        <violations-fields
          class="mx-xs-n7"
          ref="fields"
          v-model="model"
          :readonly="stepsLogic.isView" />
      </div>
      <div class="col-md-4-12">
        <h2 class="melia mb-xs-5">Фотографии нарушения</h2>
        <rir-file-list
          :files="filesViolation"
          :count-line="1"
          v-if="stepsLogic.isView"/>
        <rir-drag-upload-file
          v-else
          class="mb-xs-4"
          :count-file="0"
          :count-line="1"
          v-model="filesViolation"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'ROAD_COVER_INSPECTION',
            type: '09-11'
          }"
          :accept-file="['.jpg', '.jpeg', '.png']"/>
      </div>
    </div>
    <div
      class="row"
      v-for="(action, index) in stepsLogic.actions"
      :key="index">
      <div class="col-md-12-12">
        <rir-button
          :loading="isLoadUpdate"
          :color="action.color"
          @click="action.action">
          {{ action.text }}</rir-button>
      </div>
    </div>
    <template v-if="writingDocs.length">
      <div class="row" >
        <div class="col-md-12-12">
          <span class="melia titanic--text">Пакет документов</span>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-12-12">
          <rir-file-list
            :count-line="3"
            :files="writingDocs"/>
        </div>
      </div>
    </template>
    <template v-if="archiveDocs.length">
      <div class="row" >
        <div class="col-md-12-12">
          <span class="melia titanic--text">История документов</span>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-12-12">
          <rir-file-list
            :count-line="3"
            :files="archiveDocs"/>
        </div>
      </div>
    </template>
    <div class="row">
      <h2 class="col-md-12-12 titanic--text melia">Этапы работы по нарушению</h2>
    </div>
    <div
      class="row"
      v-for="(component) in stepsLogic.components"
      :key="component.name">
      <div class="col-md-12-12">
        <component
          :is="component"
          :item="model"
        />
      </div>
    </div>
    <div class="row" v-if="checkReport">
      <div class="col-md-12-12">
        <rir-button color="success" @click="printReport">Распечатать отчет</rir-button>
      </div>
    </div>
  </section>
  <section v-else class="d-flex justify-center">
    <loading-ring
      size="32"
    />
  </section>
</template>

<script>
import mixinLayout from '@/layout/mixins/mixinLayout'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ViolationsFields from '@/components/fields/ViolationsFields'
import stepsLogicRoad from '@/views/road/utils/stepsLogicRoad'
import PrintPage from '@/plugins/mixins/PrintPage'

export default {
  name: 'prevention-item',
  components: { ViolationsFields },
  created () {
    this.clearModel('violationData')
    this.clearModel('violationModel')
    this.clearModel('task')
    this.clearModel('act1')
    this.clearModel('act2')
  },
  async mounted () {
    this.setViolationSourceCode('ROAD_COVER_INSPECTION')
    await this.loadData()
    this.changeTitlePage(`Нарушение № ${this.violationData.number}`)
  },
  mixins: [mixinLayout, stepsLogicRoad, PrintPage],
  data: () => ({
    isLoadData: true,
    isLoadUpdate: false,
    model: null
  }),
  props: {
    violationId: {
      type: String
    }
  },
  watch: {
    'violationModel.executionTerm': function (val) {
      if (val && this.model) {
        this.model.executionTerm = val
      }
    }
  },
  computed: {
    ...mapState('violation', ['violationModel']),
    ...mapState('directory', ['statuses', 'profilesRoad']),
    ...mapGetters('session', ['isRoad']),
    isDepartmentHead () {
      return ['RCI_DEPARTMENT_HEAD', 'DIRECTOR'].includes(this.isRoad) &&
        ['CREATING_TASK']
          .includes(this.violationData.status)
    },
    nowDate () {
      return {
        date: moment(this.violationData.createdAt).format('DD MMMM YYYY'),
        hour: moment(this.violationData.createdAt).format('HH:mm')
      }
    },
    statusViolation () {
      return this.statuses.find(s => s.code === this.violationData.status)
    },
    filesViolation: {
      get () {
        return this.model.attachments.filter(attach => attach.type === '09-11')
      },
      set (val) {
        this.model.attachments = val
      }
    },
    writingDocs () {
      let docsWrite = ['09-01']
      switch (this.violationData.status) {
        case 'PREPARATION_FOR_ARCHIVE':
        case 'ELIMINATED':
        case 'DIRECTING_TO_GIBDD':
        case 'DIRECTED_TO_GIBDD':
          docsWrite = docsWrite.concat(['09-05', '09-06', '09-07', '09-08'])
        // eslint-disable-next-line no-fallthrough
        case 'SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
        case 'SENDING_CAUTION_TO_VIOLATOR':
        case 'MAKING_SENDING_ERROR_DECISION':
        case 'RECEIVING_OWNER_RESPONSE':
        case 'INSPECTING_ELIMINATION_VIOLATION':
          docsWrite = docsWrite.concat(['09-02', '09-03', '09-04'])
      }
      return this.violationModel?.attachments.filter(file =>
        docsWrite
          .includes(file.type))
    },
    archiveDocs () {
      return this.violationModel?.attachments.filter(file =>
        ['09-18', '09-19', '09-20', '09-21', '09-22', '09-23', '09-24']
          .includes(file.type))
    },
    checkReport () {
      return ['ELIMINATED', 'DIRECTED_TO_GIBDD'].includes(this.violationData.status)
    }
  },
  methods: {
    ...mapMutations('violation', ['setViolationSourceCode', 'clearModel']),
    ...mapActions('violation', ['fetchViolationItem', 'updateViolation']),
    ...mapActions('directory', ['updateExecutor']),
    async loadData () {
      this.isLoadData = true
      await this.fetchViolationItem(this.violationId)
      this.model = { ...this.violationModel }
      this.isLoadData = false
    },
    async updateItem () {
      this.$refs.fields.$v.$touch()
      if (!this.isValid) return false
      this.isLoadUpdate = true
      if (this.isDepartmentHead &&
        this.violationModel.executorId !== this.model.executorId) {
        await this.updateExecutor({
          executorId: this.model.executorId,
          id: this.violationId
        })
      }
      await this.updateViolation({
        model: this.model
      })
      this.isLoadUpdate = false
      return true
    },
    isValid () {
      this.$refs.fields.$v.$touch()
      return !this.$refs.fields.$v.$invalid
    }
  }
}
</script>
