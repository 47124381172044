<template>
  <rir-accordion>
    <div slot="activator">
      <div class="d-flex flex-column">
        <h2 class="titanic--text melia mb-xs-2">Уведомление собственника дороги</h2>
        <span class="d-flex align-center">
          <rir-icon
            class="mr-xs-2"
            :fill="statusView.iconColor"
            :icon="statusView.icon"
          />
          <span class="terminator--text amphiris anie">{{ statusView.status }}</span>
        </span>
      </div>
    </div>
    <component v-if="statusView.component" :is="statusView.component" />
    <div class="mt-xs-5 d-flex flex-column" v-if="statusView.actionsView">
      <div class="d-flex align-center">
        <rir-button
          class="mb-xs-5 memento godfather--text flex-auto"
          v-if="statusView.changeDate"
          @click="modalChangeDate()"
        >Изменить срок исполнения</rir-button>
        <rir-button
          class="mb-xs-5 memento godfather--text flex-auto ml-5"
          v-if="statusView.changeDate && btnDataStep.repeatSendMail"
          @click="changeStatusStep('RETURN')"
        >Отправить повторно</rir-button>
      </div>
      <rir-button @click="changeStatusStep(btnDataStep.decision)">
        {{ btnDataStep.btnText }}
      </rir-button>
    </div>
  </rir-accordion>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import StatusTask from '@/views/road/steps/WaitingViolator/StatusTask'
import ChangeDate from '@/components/modal/ChangeDate'

export default {
  name: 'waitingViolator',
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    statusView () {
      switch (this.violationData.status) {
        case 'RECEIVING_OWNER_RESPONSE':
        case 'MAKING_SENDING_ERROR_DECISION':
          return {
            changeDate: true,
            actionsView: ['RCI_SPECIALIST'].includes(this.isRoad),
            iconColor: 'rocky',
            icon: 'in-progress',
            status: this.violationData.status === 'RECEIVING_OWNER_RESPONSE'
              ? 'В процессе'
              : 'Ошибка отправки',
            component: StatusTask
          }
        case 'SENDING_CAUTION_TO_VIOLATOR':
          return {
            changeDate: false,
            actionsView: false,
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'Отправка материалов',
            component: StatusTask
          }
        default:
          return {
            changeDate: false,
            actionsView: false,
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Выполнено',
            component: StatusTask
          }
      }
    },
    btnDataStep () {
      switch (this.violationData.status) {
        case 'RECEIVING_OWNER_RESPONSE':
          return {
            btnText: 'Приступить к проверке',
            decision: 'APPROVE',
            repeatSendMail: false
          }
        case 'MAKING_SENDING_ERROR_DECISION':
          return {
            btnText: 'Продолжить',
            decision: 'CANCEL',
            repeatSendMail: true
          }
      }
      return {}
    }
  },
  methods: {
    ...mapActions('violation', ['changeStatusViolation']),
    modalChangeDate () {
      this.$modal(ChangeDate)
    },
    async changeStatusStep (decision) {
      await this.changeStatusViolation({ decision })
    }
  }
}
</script>
