<template>
  <section class="d-flex flex-column">
    <rir-table
      :headers="headersTable"
      :items="list.data"
    />
    <a
      @click="createEntity"
      class="violation-type__add">
      <rir-icon icon="add" fill="amelie" />
    </a>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import ListResponse from '@/models/ListResponse'
import ModalCreateOrEditEntity from '@/components/directories/ModalCreateOrEditEntity'
import ActionsDirectoriesTable from '@/components/directories/ActionsDirectoriesTable'

export default {
  name: 'entity-list',
  created () {
    this.headersTable = this.EntityData.config.formFields
      .map(f => ({ ...f }))
    this.headersTable.push({
      title: 'Действия',
      name: 'actions',
      component: ActionsDirectoriesTable,
      emit: this.returnFromForm,
      width: '112px'
    })
  },
  mounted () {
    this.loadData()
    window.onscroll = async () => {
      if (
        (window.innerHeight + window.scrollY) >= document.body.offsetHeight &&
        this.list.nextPage
      ) {
        const { data } = await this.$axios
          .get(this.list.nextPage)
        this.list.nextData(data)
      }
    }
  },
  beforeDestroy () {
    window.onscroll = () => {}
  },
  data: (vm) => ({
    isLoadScroll: false,
    EntityData: vm.$route.meta.entity,
    list: null,
    headersTable: []
  }),
  methods: {
    ...mapActions('directory', ['loadDirectories']),
    async loadData () {
      const { data } = await this.$axios
        .get(this.EntityData.config.link)
      this.list = new ListResponse(data, this.EntityData)
    },
    async createEntity () {
      const data = await this.$modal(ModalCreateOrEditEntity, {
        entity: new this.EntityData()
      })
      this.list.data.push(new this.EntityData(data))
      this.loadDirectories()
    },
    returnFromForm ({ actionType, item }) {
      if (actionType === 'DELETE') this.list.spliceEntity(item)
      this.loadDirectories()
    }
  }
}
</script>

<style lang="scss" scoped>
.violation-type{
  &__item{
    padding: 20px;
    border-radius: 16px;
    &:hover{
      background-color: var(--rir-rush);
    }
    :last-child{
      margin-bottom: 0;
    }
  }
  &__add{
    position: fixed;
    bottom: 40px;
    right: 40px;
    height: 40px;
    width: 40px;
    background-color: var(--rir-rocky);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
