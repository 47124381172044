<template>
  <rir-cards-block>
    <section slot="title" class="nomira px-xs-8">Нарушение №123465</section>
    <section slot="default" class="d-flex flex-column">
      <div class="row">
        <div class="col-md-12-12 d-flex align-center">
          <rir-icon icon="calendar" class="mr-xs-2"/>
          <span class="mr-xs-4 kleodora titanic--text biqe">{{ nowDate.date }}</span>
          <rir-icon icon="clock"  class="mr-xs-2"/>
          <span class="kleodora titanic--text biqe">{{ nowDate.hour }}</span>
        </div>
      </div>
      <div class="row flex-column">
        <div class="col-md-12-12 d-flex align-center">
          <rir-icon icon="avatar" class="mr-xs-2"/>
          <span class="mr-4 kleodora titanic--text biqe">{{ violationData.executorName }}</span>
        </div>
        <div class="col-md-12-12 d-flex align-center">
        <span
          class="kleodora">{{ statusViolation }}</span>
        </div>
      </div>
      <div class="px-xs-8 mb-xs-5 titanic--text melia">Изменение срока исполнения</div>
      <rir-date-picker
        class="px-xs-8"
        click-close
        is-time
        :start-date="startDate"
        v-model="newDate"
      />
    </section>
    <section
      slot="actions"
      class="px-xs-8"
    >
      <rir-button @click="saveDate">Сохранить</rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'change-date',
  created () {
    this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + 1)).toLocaleDateString('en-CA')
  },
  mounted () {
    this.newDate = this.violationModel.executionTerm
  },
  data: () => ({
    newDate: null,
    startDate: new Date()
  }),
  computed: {
    ...mapState('violation', ['violationModel', 'violationData']),
    ...mapState('directory', ['statuses']),
    nowDate () {
      return {
        date: moment(this.violationData.createdAt).format('DD MMMM YYYY'),
        hour: moment(this.violationData.createdAt).format('HH:mm')
      }
    },
    statusViolation () {
      return this.statuses.find(s => s.code === this.violationData.status).name
    }
  },
  methods: {
    ...mapActions('violation', ['changeDateViolation']),
    saveDate () {
      console.log(this.newDate)
      this.changeDateViolation({ date: this.newDate })
      this.$attrs.closeModal(this.newDate)
    }
  }
}
</script>
