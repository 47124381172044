<template>
  <rir-cards-block>
    <div slot="title" class="d-flex flex-column px-6">
      <h2 class="nomira mb-2">Оставить комментарий</h2>
      <span class="metioche titanic--text anie">Нарушение № {{ violationData.number }}</span>
    </div>
    <div
      class="row row--no-grep px-2">
      <div
        v-if="isPhone && isPrevention"
        class="col-md-6-12">
        <rir-select
          label="Статус созвона"
          :items="phoneCallStatus"
          text-value="name"
          id-value="code"
          v-model="phoneCallStatusCode"
        />
      </div>
      <div class="col-md-12-12">
        <rir-textarea
          v-model="additionalInfo"
          label="Введите текст комментария"/>
      </div>
      <div class="col-md-12-12 mb-0">
        <rir-button
          :loading="isSend"
          @click="update">Сохранить</rir-button>
      </div>
    </div>
  </rir-cards-block>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'change-note',
  created () {
    this.phoneCallStatusCode = this.violationModel.phoneCallStatusCode
    this.additionalInfo = this.violationModel.additionalInfo
  },
  data: () => ({
    isSend: false,
    phoneCallStatusCode: null,
    additionalInfo: null
  }),
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapState('directory', ['phoneCallStatus']),
    isPrevention () {
      return this.violationData.violationSourceCode === 'PREVENTION'
    },
    isPhone () {
      return !!this.violationModel?.violator?.phone
    }
  },
  methods: {
    ...mapActions('violation', ['updateViolation']),
    async update () {
      this.isSend = true
      try {
        await this.updateViolation({
          model: {
            ...this.violationModel,
            phoneCallStatusCode: this.phoneCallStatusCode,
            additionalInfo: this.additionalInfo
          }
        })
        this.$attrs.closeModal()
        this.$notification({
          type: 'success',
          text: 'Данные сохранены'
        })
      } catch {
        this.$notification({
          type: 'error',
          text: 'Произошла ошибка, повторить попытку занового'
        })
      }
      this.isSend = false
    }
  }
}
</script>
