<template>
  <div class="d-flex align-center justify-center" v-if="!violationId">
    Выберите нарушение из списка
  </div>
  <div class="d-flex align-center justify-center" v-else-if="isUpdateData">
    <loading-ring
      :size="32"
      color="#3D75E4"/>
  </div>
  <section class="violation-view" v-else>
    <div class="d-flex align-center mb-2 px-4">
      <span class="melia mr-4">Нарушение № {{ violationData.number }}</span>
      <router-link v-if="violationData.status !== 'CANCELLED_TASK'"
        :to="{
          name: isPrevention ? 'prevention-item' : 'road-item',
          params: { violationId: violationId }
        }"
        class="d-flex align-center">
        <rir-icon
          class="mr-1"
          icon="edit"/>
        <span class="marynae rocky--text">Редактировать</span>
      </router-link>
    </div>
    <div
      class="d-flex align-center mb-4 px-4">
      <rir-icon
        class="mr-1"
        icon="avatar"/>
      <span class="marynae rocky--text">{{ violationData.executorName }}</span>
    </div>
    <div class="row row--no-grep">
      <div class="col-lg-6-12 col-md-12-12 d-flex align-center">
        <rir-form-item
          style="width: 100%"
          label="Фотографии нарушения">
          <rir-gallery
            v-if="photoViolation.length"
            :files="photoViolation"
          />
          <div class="violation-view__no-photo" v-else>
            <span class="clymenti titanic--text anie">Фотографии отсутствуют</span>
          </div>
        </rir-form-item>
      </div>
      <div class="col-lg-6-12 col-md-12-12">
        <rir-form-item
          style="width: 100%"
          label="Фотографии после устранения">
          <rir-gallery
            v-if="photoViolationCorrection.length"
            :files="photoViolationCorrection"
          />
          <div class="violation-view__no-photo" v-else>
            <span class="clymenti titanic--text anie">Фотографии отсутствуют</span>
          </div>
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="Объект контроля">
          {{ objectTypeInfo.name }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="Вид нарушения">
          {{ violationTypeInfo }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="ФИО нарушителя">
          {{ violator.fullName || 'Отсутствует' }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="Название юридического лица">
          {{ violator.legalName || 'Отсутствует'}}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-lg-6-12 col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="E-mail">
          {{ violator.email || 'Отсутствует' }}
        </rir-form-item>
      </div>
      <div
        class="col-lg-6-12 col-md-12-12 d-flex align-center"
        style="flex-wrap: wrap"
      >
        <rir-form-item
          :style-label="styleLabel"
          class="mr-4"
          label="Телефон">
          {{ violator.phone || 'Отсутствует' }}
        </rir-form-item>
        <template v-if="actionsPhone && isPrevention">
          <rir-tooltip
            activator-hover
          >
            <rir-button
              slot="activator"
              :disabled="!isPhoneProfile"
              class="mr-4"
              icon="telephone"
              @click="sendCallApiPhone"
            />
            <span>{{ isPhoneProfile ? 'Вызвать нарушителя' : 'Укажите телефон в профиле' }}</span>
          </rir-tooltip>
        </template>
        <rir-tooltip
          v-if="isNotNote"
          activator-hover
        >
          <rir-button
            slot="activator"
            @click="changeNote"
            class="mr-4"
            color="secondary"
            icon="message"
          />
          <span>Изменить доп. информацию</span>
        </rir-tooltip>
      </div>
    </div>
    <div
      class="row row--no-grep"
      v-if="actionsPhone">
      <div class="col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="Статус созвона">
          {{ callStatus }}
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-form-item
          :style-label="styleLabel"
          label="Дополнительная информация">
          {{ violationModel.additionalInfo || 'Комментария нет' }}
        </rir-form-item>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import getEnv from '@/plugins/env'
import ChangeNote from '@/components/modal/ChangeNote'

export default {
  name: 'violation-view',
  data: () => ({
    styleLabel: { marginBottom: '0px' },
    isLoadData: false
  }),
  props: {
    violationId: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  watch: {
    violationId: function () {
      this.loadData()
    }
  },
  computed: {
    ...mapState('session', ['profile']),
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapState('directory', ['inspectionObject', 'violationType', 'phoneCallStatus']),
    isPrevention () {
      return this.violationData.violationSourceCode === 'PREVENTION'
    },
    callStatus () {
      return this.phoneCallStatus.find(el => el.code === this.violationModel.phoneCallStatusCode)?.name ||
        'Ожидает звонка'
    },
    objectTypeInfo () {
      return this.inspectionObject.find(el => el.code === this.violationModel.inspectionObjectCode)
    },
    violationTypeInfo () {
      return this.violationType.find(el => el.code === this.violationModel.violationTypeCode)?.name || 'Отсутствует'
    },
    violator () {
      return this.violationModel.violator || {
        email: null,
        phone: null,
        fullName: null,
        legalName: null
      }
    },
    photoViolation () {
      return this.violationModel.attachments.filter(el =>
        el.type === (this.isPrevention ? '08-01' : '09-11'))
    },
    photoViolationCorrection () {
      return this.violationModel.attachments.filter(el =>
        el.type === (this.isPrevention ? '08-02' : '09-12'))
    },
    isUpdateData () {
      return this.loading || this.isLoadData
    },
    actionsPhone () {
      return !!+getEnv('VUE_APP_IS_API_PHONE') &&
        !['DIRECTED_TO_MUNICIPAL_CONTROL', 'ELIMINATED', 'CANCELLED_DRAFT']
          .includes(this.violationData.status) &&
        !!this.violator.phone
    },
    isPhoneProfile () {
      return !!this.profile?.phone
    },
    isNotNote () {
      return ![
        'DIRECTED_TO_MUNICIPAL_CONTROL',
        'ELIMINATED',
        'CANCELLED_TASK',
        'DIRECTING_TO_GIBDD',
        'CANCELLED_DRAFT'
      ]
        .includes(this.violationData.status)
    }
  },
  methods: {
    ...mapActions('violation', ['fetchViolationItem']),
    async loadData () {
      this.isLoadData = true
      !!this.violationId && await this.fetchViolationItem(this.violationId)
      this.isLoadData = false
    },
    async changeNote () {
      await this.$modal(ChangeNote, {})
    },
    sendCallApiPhone () {
      const r = /\d+/g
      const params = new URLSearchParams({
        from: this.profile.phone.match(r).join(''),
        to: this.violator.phone.match(r).join('')
      })
      window.open(`${getEnv('VUE_APP_URL_API_PHONE')}?${params.toString()}`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.violation-view{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &__no-photo{
    width: 100%;
    padding-bottom: calc(100% * 9 / 16);
    background: #E4EDFB;
    border-radius: 16px;
    position: relative;
    & > span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
