export default {
  async getUserInfo ({ rootGetters, commit, getters }) {
    const user = await rootGetters.GET('get-user')
    commit('setProperty', { name: 'user', value: user })
    if (['PREV_INSPECTOR', 'RCI_SPECIALIST'].includes(getters.isPrevention || getters.isRoad)) {
      const profile = await rootGetters.GET('violation/executor-profile')
      commit('setProperty', { name: 'profile', value: profile })
    }
  },
  async updateProfile ({ rootGetters, commit, state }, model) {
    await rootGetters.PUT('violation/executor-profile', model)
    commit('setProperty', { name: 'profile', value: { ...state.profile, ...model } })
  }
}
