<template>
  <section></section>
</template>

<script>
export default {
  name: 'error-page',
  mounted () {
    // this.$keycloak.logoutFn()
  }
}
</script>
