<template>
  <section class="d-flex flex-column">
    <div class="d-flex align-center">
      <rir-icon
        :fill="statusType.iconColor"
        class="mr-xs-2"
        :icon="statusType.icon"
      />
      <span>{{ statusType.status }}</span>
    </div>
    <span class="pl-xs-6 titanic--text anie">{{ statusType.text }}</span>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'status-task',
  computed: {
    ...mapState('violation', ['violationData']),
    statusType () {
      switch (this.violationData.status) {
        case 'PREPARATION_FOR_ARCHIVE':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Ожидание сбора пакета документов'
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Нарушение отправлен в архив'
          }
      }
    }
  }
}
</script>
