<template>
  <section class="d-flex flex-column">
    <span
      class="kleodora anie terminator--text mb-4">
      Если требуется, актуализируйте ранее загруженные документы
    </span>
    <rir-button
      class="mb-8"
      color="secondary"
      @click="openSetDoc"
    >
      Добавить актуальные документы
    </rir-button>
    <span
      style="width: 500px"
      class="kleodora anie terminator--text mb-4"
    >Сформируйте документ, проверьте его, при необходимости исправьте, и загрузите обратно в систему</span>
    <rir-button
      @click="generateGibdd"
      color="secondary"
      class="mb-xs-5">Сформировать документ</rir-button>
    <rir-drag-upload-file
      class="mb-8"
      :count-file="1"
      :count-line="1"
      v-model="attach"
      :upload-url="$root.$uploadServer"
      :params-body="{
        code: 'ROAD_COVER_INSPECTION',
        type: '09-08'
      }"
      :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
    <rir-checkbox
      class="mb-8"
      v-model="isAllDoc"
      label="Документы готовы к отправке в архив"
    />
    <rir-button
      @click="changeStatus"
      :disabled="!attach.length || !isAllDoc">Отправить</rir-button>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import UpdatePackageDoc from '@/views/road/modal/UpdatePackageDoc'

export default {
  name: 'generate-message',
  mounted () {
    this.attach = this.violationModel.attachments.filter(file => file.type === '09-08')
  },
  data: () => ({
    attach: [],
    isAllDoc: false
  }),
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapGetters(['GET_BLOB']),
    checkReturn () {
      switch (this.violationData.status) {
        case 'RETURNED_FROM_SIGNING_BY_DEPARTMENT_DIRECTOR':
          return {
            text: 'Вовзрат на доработку от директора'
          }
        default:
          return null
      }
    }
  },
  methods: {
    ...mapMutations('violation', ['changeViolationAttachments']),
    ...mapActions('violation', ['updateViolation', 'changeStatusViolation', 'changeSelectAllDoc']),
    async generateGibdd () {
      const { data } = await this.GET_BLOB(`violation/road-cover-inspection/${this.violationData.id}/gibdd`)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Письмо в ГИБДД.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async changeStatus () {
      this.changeViolationAttachments({
        clearType: '09-08',
        files: this.attach
      })
      await this.updateViolation({
        model: this.violationModel
      })
      await this.changeSelectAllDoc({
        value: this.isAllDoc
      })
      await this.changeStatusViolation({
        decision: 'APPROVE'
      })
    },
    openSetDoc () {
      this.$modal(UpdatePackageDoc)
    }
  }
}
</script>
