<template>
  <rir-cards-block>
    <span
      class="px-5 nomira"
      slot="title">
      {{ contentData.title }}
    </span>
    <section class="px-5">
      <form @submit.prevent="saveData()">
        <rir-form-item
          v-for="f in fields"
          :key="f.column"
          :error="$v.entity[f.column].$error"
          :message="mesErr($v.entity[f.column])">
          <rir-input
            v-if="f.mask"
            v-mask="f.mask"
            :label="f.title"
            v-model.trim="entity[f.column]"
          />
          <rir-input
            v-else
            :label="f.title"
            v-model.trim="entity[f.column]"
          />
        </rir-form-item>
      </form>
    </section>
    <section
      class="px-5"
      slot="actions">
      <rir-button
        :loading="isSubmit"
        @click="saveData">
        {{ contentData.btnText }}
      </rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, regEx } from '@/plugins/vuelidate/customValidate'
import { mask } from 'vue-the-mask'
export default {
  name: 'modal-create-or-edit-entity',
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  created () {
    this.state = { ...this.entity }
  },
  mounted () {
    this.fields = this.entity.constructor.config.formFields
  },
  data: () => ({
    isSubmit: false,
    fields: [],
    state: null
  }),
  beforeDestroy () {
    !this.isSubmit && this.entity.setDataEntity(this.state)
  },
  props: {
    entity: {
      type: Object
    }
  },
  validations () {
    const v = this.fields.reduce((validate, el) => {
      validate[el.column] = el.validate.reduce((valid, v) => {
        switch (v.type) {
          case 'require':
            valid.require = required(v.text)
            break
          case 'regEx':
            valid.regEx = regEx(v.re, v.text)
            break
        }
        return valid
      }, {})
      return validate
    }, {})
    return {
      entity: v
    }
  },
  computed: {
    apiSetting () {
      return {
        method: this.entity.id ? 'put' : 'post',
        url: `${this.entity.constructor.config.link}${this.entity.id || ''}`
      }
    },
    contentData () {
      return {
        title: `${(this.state.id ? 'Редактирование' : 'Добавление')}`,
        btnText: `${(this.state.id ? 'Сохранить' : 'Создать')}`
      }
    }
  },
  methods: {
    async saveData () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isSubmit = true
      try {
        const { data } = await this
          .$axios[this.apiSetting.method](this.apiSetting.url, this.entity.DataApi)
        this.$attrs.closeModal({ ...this.entity.DataApi, ...data })
      } catch {
        this.isSubmit = false
      }
    }
  }
}
</script>
