<template>
  <section class="d-flex flex-column">
    <span
      class="kleodora anie terminator--text mb-4">
      Если требуется, актуализируйте ранее загруженные документы
    </span>
    <rir-button
      class="mb-8"
      color="secondary"
      @click="openSetDoc"
    >
      Добавить актуальные документы
    </rir-button>
    <rir-checkbox
      class="mb-8"
      v-model="isAllDoc"
      label="Документы готовы к отправке в архив"
    />
    <rir-button
      :disabled="!isAllDoc"
      @click="closeViolation"
    >
      Отправить в архив
    </rir-button>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UpdatePackageDoc from '@/views/road/modal/UpdatePackageDoc'

export default {
  name: 'check-all-docs',
  data: () => ({
    isAllDoc: false
  }),
  computed: {
    ...mapState('violation', ['violationData']),
    statusType () {
      switch (this.violationData.status) {
        case 'FIXING_VIOLATION':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Ожидание пакета документов'
          }
        // Подпись задания у начальника
        case 'SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          return {
            iconColor: 'rocky',
            icon: 'waiting',
            status: 'Отправлено директору на утверждение',
            text: 'Ожидаем утверждение'
          }
        case 'RETURNED_FROM_SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Возврат на доработку от директора'
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Подписано директором',
            text: 'Решение принято'
          }
      }
    }
  },
  methods: {
    ...mapActions('violation', ['changeSelectAllDoc', 'changeStatusViolation']),
    async closeViolation () {
      await this.changeSelectAllDoc({
        value: this.isAllDoc
      })
      await this.changeStatusViolation({
        decision: 'APPROVE'
      })
    },
    openSetDoc () {
      this.$modal(UpdatePackageDoc)
    }
  }
}
</script>
