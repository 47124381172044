<template>
  <section class="d-flex flex-column">
    <div class="titanic--text melia mb-xs-4">Предостережение</div>
    <div class="d-flex align-center">
      <rir-icon
        :fill="statusType.iconColor"
        class="mr-xs-2"
        :icon="statusType.icon"
      />
      <span>{{ statusType.status }}</span>
    </div>
    <span class="pl-xs-6 titanic--text anie">{{ statusType.text }}</span>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'status-task',
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    statusType () {
      let startDate
      let finishDate
      let date
      switch (this.violationData.status) {
        case 'RECEIVING_OWNER_RESPONSE':
        case 'MAKING_SENDING_ERROR_DECISION':
          startDate = moment(this.violationData.createdAt)
          finishDate = moment(this.violationModel.executionTerm)
          date = startDate.to(finishDate, true)
          return {
            iconColor: 'rocky',
            icon: 'notifications',
            status: this.violationData.status === 'RECEIVING_OWNER_RESPONSE'
              ? 'Отправлено, ожидаем ответ'
              : 'Собственник не получил документы',
            text: `Осталось ${date}. ${this.violationData.status === 'MAKING_SENDING_ERROR_DECISION' ? ' Отправьте заново или продолжите без отправки' : ''}`
          }
        case 'SENDING_CAUTION_TO_VIOLATOR':
          return {
            iconColor: 'rocky',
            icon: 'notifications',
            status: 'Идет отправка письма',
            text: 'Ожидайте завершения отправки'
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Проверка участка'
          }
      }
    }
  }
}
</script>
