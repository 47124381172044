<template>
  <section>
    <h2 class="titanic--text clymenti mb-xs-5">Форма дорожного контроля</h2>
    <rir-form-item
      :error="$v.model.formCode.$error"
      :message="mesErr($v.model.formCode)"
    >
      <rir-radio-group
        is-column
        v-model="model.formCode"
      >
        <rir-radio
          v-for="form in taskForm"
          :readonly="isRead"
          :label="form.name"
          :value="form.code"
          :key="form.code"
        />
      </rir-radio-group>
    </rir-form-item>
    <rir-form-item
      :error="$v.model.territory.$error"
      :message="mesErr($v.model.territory)"
    >
      <rir-textarea
        v-model="model.territory"
        :readonly="isRead"
        class="mt-xs-4 mb-xs-6"
        label="Территория"
      />
    </rir-form-item>
    <h2 class="titanic--text clymenti mb-xs-5">Предмет дорожного контроля</h2>
    <rir-form-item
      :error="$v.model.subjectCode.$error"
      :message="mesErr($v.model.subjectCode)"
    >
      <rir-radio-group
        is-column
        v-model="model.subjectCode"
      >
        <rir-radio
          v-for="subject in taskSubject"
          :readonly="isRead"
          :label="subject.name"
          :value="subject.code"
          :key="subject.code"
        />
      </rir-radio-group>
    </rir-form-item>
    <h2 class="titanic--text clymenti mb-xs-5 mt-xs-5">Период проведения</h2>
    <div class="row row--no-grep">
      <div class="col-md-4-12 pl-xs-0">
        <rir-form-item
          :error="$v.model.startDate.$error"
          :message="mesErr($v.model.startDate)"
        >
          <rir-date-picker
            v-mask="'##.##.####, ##:##'"
            :readonly="isRead"
            v-model="model.startDate"
            is-time
            label="Начало мероприятия"/>
        </rir-form-item>
      </div>
      <div class="col-md-4-12">
        <rir-form-item
          :error="$v.model.finishDate.$error"
          :message="mesErr($v.model.finishDate)"
        >
          <rir-date-picker
            v-mask="'##.##.####, ##:##'"
            v-model="model.finishDate"
            :readonly="isRead"
            is-time
            label="Конец мероприятия"/>
        </rir-form-item>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-6-12 terminator--text kleodora anie mb-xs-5">
        Сформируйте документ задания, проверьте и при необходимости отредактируйте его, после чего загрузите в систему
      </div>
      <rir-button
        :loading="isGenerateDocLoad"
        @click="downloadDoc"
        class="memento godfather--text mb-xs-5">
        Сформировать документ
      </rir-button>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, moreThan, lessThan } from '@/plugins/vuelidate/customValidate'

export default {
  name: 'form-generate-task',
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  created () {
    this.model = { ...this.task }
  },
  data: () => ({
    isGenerateDocLoad: false,
    model: {
      territory: null,
      startDate: null,
      finishDate: null,
      formCode: null,
      subjectCode: null
    }
  }),
  validations: {
    model: {
      territory: {
        required: required('Обязательное поле')
      },
      formCode: {
        required: required('Обязательное поле')
      },
      subjectCode: {
        required: required('Обязательное поле')
      },
      startDate: {
        required: required('Обязательное поле'),
        lessThan: lessThan('finishDate', 'Дата должна быть меньше чем конечная')
      },
      finishDate: {
        required: required('Обязательное поле'),
        moreThan: moreThan('startDate', 'Дата должна быть больше чем начальная')
      }
    }
  },
  props: {
    isRead: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('violation', ['task', 'violationData']),
    ...mapState('directory', ['taskForm', 'taskSubject']),
    ...mapGetters(['POST_BLOB'])
  },
  methods: {
    ...mapMutations('violation', ['setProperty']),
    async downloadDoc () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isGenerateDocLoad = true
      const { data } = await this.POST_BLOB(`violation/road-cover-inspection/${this.violationData.id}/task`, this.model)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Задание.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setProperty({ name: 'task', value: this.model })
      this.isGenerateDocLoad = false
    }
  }
}
</script>
