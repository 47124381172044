<template>
  <rir-cards-block>
    <a @click="$attrs.closeModal" class="d-flex align-center px-8" slot="status">
      <rir-icon class="mr-2" icon="arrow-left" />
      Назад
    </a>
    <h2 slot="title" class="nomira px-8">Личная карточка сотрудника</h2>
    <section>
      <div class="row">
        <div class="col-md-12-12 d-flex flex-column">
          <span class="titanic--text melia mb-1">{{ fullName }}</span>
          <span class="terminator--text ekas">{{ roleName }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12-12 d-flex flex-column">
          <span class="kleodora terminator--text anie">Код активации чат-бота </span>
          <span class="titanic--text melia mb-1">{{ profile.telegramCode }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12-12 d-flex flex-column">
          <rir-input
            class="mb-xs-6"
            v-mask="'+7 (###) ###-##-##'"
            label="Номер телефона"
            v-model="model.phone"
          />
          <rir-input
            class="mb-xs-6"
            label="Фотоаппарат/смартфон"
            v-model="model.camera"/>
          <rir-input
            class="mb-xs-6"
            label="Измерительный инструмент"
            v-model="model.measurementTool"/>
          <rir-button @click="saveProfile">Сохранить</rir-button>
        </div>
      </div>
    </section>
  </rir-cards-block>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'user-settings',
  directives: { mask },
  mounted () {
    Object.keys(this.model).forEach(key => {
      this.model[key] = this.profile[key]
    })
  },
  data: () => ({
    model: {
      phone: null,
      camera: null,
      measurementTool: null
    }
  }),
  computed: {
    ...mapGetters('session', ['roleName', 'fullName']),
    ...mapState('session', ['profile'])
  },
  methods: {
    ...mapActions('session', ['updateProfile']),
    async saveProfile () {
      await this.updateProfile(this.model)
      this.$attrs.closeModal()
    }
  }
}
</script>
