<template>
  <rir-popover
    click-close
    class="mr-3"
    v-if="relationships"
    max-width="250"
    v-model="isShowList"
  >
    <template v-slot:activator="{ on }">
      <rir-button
        icon="link"
        transparent
        v-on="on"
      />
    </template>
    <section>
      <rir-list>
        <rir-list-item
          v-for="(relationship, index) in relationships"
          :key="index"
          @click.native="openRelationship(index)"
        >{{ relationship.setting.name }}</rir-list-item>
      </rir-list>
    </section>
  </rir-popover>
</template>

<script>
export default {
  name: 'lis-action-relationships',
  data: (vm) => ({
    relationships: vm.item.constructor.config.relationships || null,
    isShowList: false
  }),
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    async openRelationship (type = 'Available') {
      const component = await import(`./Modal${type}.vue`)
      this.$modal(component.default, {
        entity: this.item
      })
      this.isShowList = false
    }
  }
}
</script>
