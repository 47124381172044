var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"d-flex flex-column"},[_c('form-generate-act',{attrs:{"is-read":!_vm.isEdit}}),(_vm.isEdit)?_c('rir-drag-upload-file',{attrs:{"count-file":1,"count-line":1,"upload-url":_vm.$root.$uploadServer,"params-body":{
      code: 'ROAD_COVER_INSPECTION',
      type: '09-05'
    },"accept-file":['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']},model:{value:(_vm.attachAct),callback:function ($$v) {_vm.attachAct=$$v},expression:"attachAct"}}):_vm._e(),_c('div',{staticClass:"row row--no-grep mt-xs-5"},[_c('div',{staticClass:"col-md-6-12 flex-column pl-xs-0"},[_c('h2',{staticClass:"titanic--text melia mb-xs-5"},[_vm._v("Фототаблица №2")]),_c('div',{staticClass:"terminator--text kleodora anie mb-xs-5"},[_vm._v("Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему")]),_c('rir-button',{staticClass:"memento godfather--text mb-xs-5",attrs:{"loading":_vm.isLoadingPhoto},on:{"click":_vm.generatePhotoTable}},[_vm._v("Сформировать документ")]),(_vm.isEdit)?_c('rir-drag-upload-file',{attrs:{"count-file":1,"count-line":1,"upload-url":_vm.$root.$uploadServer,"params-body":{
          code: 'ROAD_COVER_INSPECTION',
          type: '09-06'
        },"accept-file":['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']},model:{value:(_vm.attachPhotoTable),callback:function ($$v) {_vm.attachPhotoTable=$$v},expression:"attachPhotoTable"}}):_vm._e()],1),_c('div',{staticClass:"col-md-6-12 flex-column pr-xs-0"},[_c('h2',{staticClass:"titanic--text melia mb-xs-5"},[_vm._v("Мотивированное представление")]),_c('div',{staticClass:"terminator--text kleodora anie mb-xs-5"},[_vm._v("Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему")]),_c('rir-button',{staticClass:"memento godfather--text mb-xs-5",on:{"click":_vm.generateMotivation}},[_vm._v("Сформировать документ")]),(_vm.isEdit)?_c('rir-drag-upload-file',{attrs:{"count-file":1,"count-line":1,"upload-url":_vm.$root.$uploadServer,"params-body":{
          code: 'ROAD_COVER_INSPECTION',
          type: '09-07'
        },"accept-file":['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']},model:{value:(_vm.attachWarning),callback:function ($$v) {_vm.attachWarning=$$v},expression:"attachWarning"}}):_vm._e()],1)]),(_vm.isEdit)?_c('rir-button',{attrs:{"disabled":_vm.checkAllFile},on:{"click":function($event){return _vm.changeStatus(true)}}},[_vm._v("Закончить работы по нарушению")]):_vm._e(),(_vm.isEdit)?_c('rir-button',{staticClass:"mt-xs-5",attrs:{"color":"error","disabled":_vm.checkAllFile},on:{"click":function($event){return _vm.changeStatus(false)}}},[_vm._v("Направить в ГИБДД")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }