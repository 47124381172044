<template>
  <section>
    <h2 class="titanic--text melia mb-xs-3">Акт №1</h2>
    <div class="row row--no-grep">
      <div class="col-md-6-12 flex-column pl-xs-0">
        <rir-form-item
          :error="$v.model.inspectionBorders.$error"
          :message="mesErr($v.model.inspectionBorders)"
        >
          <rir-textarea
            v-model="model.inspectionBorders"
            :readonly="isRead"
            class="mt-xs-4 mb-xs-6"
            :rows="3"
            label="Границы осмотра"
          />
        </rir-form-item>
        <rir-form-item
          :error="$v.model.violationDescription.$error"
          :message="mesErr($v.model.violationDescription)"
        >
          <rir-textarea
            v-model="model.violationDescription"
            :readonly="isRead"
            :rows="20"
            class="mt-xs-4 mb-xs-6"
            label="Описание нарушения"
          />
        </rir-form-item>
        <div class="terminator--text kleodora anie mb-xs-5">
          Сформируйте документ, проверьте его, при необходимости исправьте, и загрузите обратно в систему
        </div>
      </div>
    </div>
    <rir-button
      :loading="isGenerateDoc"
      class="memento godfather--text mb-xs-5" @click="downloadDoc">Сформировать документ</rir-button>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'

export default {
  name: 'form-generate-act',
  mixins: [validationMixin, validateMixin],
  mounted () {
    this.model = { ...this.act1 }
  },
  data: () => ({
    isGenerateDoc: false,
    model: {
      inspectionBorders: null,
      violationDescription: null
    }
  }),
  validations: {
    model: {
      inspectionBorders: {
        required: required('Обязательное поле')
      },
      violationDescription: {
        required: required('Обязательное поле')
      }
    }
  },
  props: {
    isRead: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('violation', ['act1', 'violationData']),
    ...mapGetters(['POST_BLOB'])
  },
  methods: {
    ...mapMutations('violation', ['setProperty']),
    async downloadDoc () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isGenerateDoc = true
      const data = await this.POST_BLOB(`violation/road-cover-inspection/${this.violationData.id}/act1`, this.model)
      const url = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Акт1.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setProperty({ name: 'act1', value: this.model })
      this.isGenerateDoc = false
    }
  }
}
</script>
