<template>
  <section class="d-flex flex-column">
    <section v-if="checkReturn" class="mb-xs-4 d-flex flex-column">
      <div>{{ checkReturn.text }}</div>
      <div>{{ violationData.note }}</div>
    </section>
    <form-generate-docs />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import FormGenerateDocs from '@/views/road/steps/SigningWarningWithDirector/FormGenerateDocs'

export default {
  name: 'generate-task',
  components: { FormGenerateDocs },
  data: () => ({}),
  computed: {
    ...mapState('violation', ['violationData']),
    checkReturn () {
      switch (this.violationData.status) {
        case 'RETURNED_FROM_SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          return {
            text: 'Вовзрат на доработку от директора'
          }
        default:
          return null
      }
    }
  }
}
</script>
