export default {
  // rolePlatform (state) {
  //   const roles = new Map([
  //     ['VIOLATION.PREVENTION.INSPECTOR', 'PREV_INSPECTOR'],
  //     ['VIOLATION.PREVENTION.DEPARTMENT_HEAD', 'PREV_DEPARTMENT_HEAD'],
  //     ['VIOLATION.RCI.SPECIALIST', 'RCI_SPECIALIST'],
  //     ['VIOLATION.RCI.DEPARTMENT_HEAD', 'RCI_DEPARTMENT_HEAD'],
  //     ['VIOLATION.RCI.VICE_MAYOR', 'VICE_MAYOR'],
  //     ['VIOLATION.RCI.DEPARTMENT_DIRECTOR', 'DIRECTOR']
  //   ])
  //   return roles.get(state.user.roles.find(el => roles.get(el)))
  // },
  roleName (state) {
    const roles = new Map([
      ['VIOLATION.PREVENTION.INSPECTOR', 'Специалист проф.'],
      ['VIOLATION.PREVENTION.DEPARTMENT_HEAD', 'Начальник проф.'],
      ['VIOLATION.RCI.SPECIALIST', 'Специалист ДК'],
      ['VIOLATION.RCI.DEPARTMENT_HEAD', 'Начальник ДК'],
      ['VIOLATION.RCI.VICE_MAYOR', 'Вице-мэр'],
      ['VIOLATION.RCI.DEPARTMENT_DIRECTOR', 'Директор']
    ])

    const user = state.user
    return roles.get(user.roles.find(el => roles.get(el)))
  },
  isPrevention (state) {
    const roles = new Map([
      ['VIOLATION.PREVENTION.INSPECTOR', 'PREV_INSPECTOR'],
      ['VIOLATION.PREVENTION.DEPARTMENT_HEAD', 'PREV_DEPARTMENT_HEAD']
    ])
    return roles.get(state.user.roles.find(el => roles.get(el))) || false
  },
  isRoad (state) {
    const roles = new Map([
      ['VIOLATION.RCI.SPECIALIST', 'RCI_SPECIALIST'],
      ['VIOLATION.RCI.DEPARTMENT_HEAD', 'RCI_DEPARTMENT_HEAD'],
      ['VIOLATION.RCI.VICE_MAYOR', 'VICE_MAYOR'],
      ['VIOLATION.RCI.DEPARTMENT_DIRECTOR', 'DIRECTOR']
    ])
    return roles.get(state.user.roles.find(el => roles.get(el))) || false
  },
  isGlobalHead (state, getters) {
    return getters.isPrevention && getters.isRoad
  },
  fullName (state) {
    const user = state.user
    return `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`.trim() || 'Не указано ФИО'
  },
  infoName (state) {
    const user = state.user
    return user.firstName
  }
}
