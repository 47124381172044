<template>
  <rir-drag-upload-file
    :count-file="0"
    :count-line="2"
    v-model="attachmentsFinish"
    :upload-url="$root.$uploadServer"
    :params-body="{
      code: 'PREVENTION',
      type: '08-02'
    }"
    :accept-file="['.jpg', '.jpeg', '.png']"/>
</template>

<script>
export default {
  name: 'upload-finish-photo',
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    attachmentsFinish: {
      get () {
        return this.value.attachments.filter(attach => attach.type === '08-02')
      },
      set (val) {
        const model = { ...this.value }
        const attach = model.attachments.filter(attach => attach.type !== '08-02')
        model.attachments = attach.concat(val)
        this.$emit('input', model)
      }
    }
  }
}
</script>
