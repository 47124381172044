export default {
  inspectionObjectInSourceCode (state, getters, rootState) {
    const isSource = rootState.violation.violationData.violationSourceCode
    return state.inspectionObject.filter(io => io.violationSourceCode === isSource)
  },
  statusesInSourceCode (state, getters, rootState) {
    const isSource = rootState.violation.violationData.violationSourceCode
    return state.statuses.filter(s => s.processCode === isSource)
  },
  violatorsList (state) {
    return [{ id: null, legalName: 'Другая организация' }, ...state.violators]
  }
}
