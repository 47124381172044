<template>
  <section>
    <div class="row row--no-grep">
      <div class="col-md-12-12 melia">
        Фотографии проверки устранения нарушения
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12">
        <rir-file-list
          :files="attachmentsFinish"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'upload-finish-photo',
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    attachmentsFinish () {
      return this.value.attachments.filter(attach => attach.type === '08-02')
    }
  }
}
</script>
