<template>
  <rir-accordion>
    <div slot="activator">
      <div class="d-flex flex-column">
        <h2 class="titanic--text melia mb-xs-2">Отправка письма в ГИБДД</h2>
        <span class="d-flex align-center">
          <rir-icon
            class="mr-xs-2"
            :fill="statusView.iconColor"
            :icon="statusView.icon"
          />
          <span class="terminator--text amphiris anie">{{ statusView.status }}</span>
        </span>
      </div>
    </div>
    <component v-if="statusView.component" :is="statusView.component" />
  </rir-accordion>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import StatusTask from '@/views/road/steps/MessageGibdd/StatusTask'
import GenerateMessage from '@/views/road/steps/MessageGibdd/GenerateMessage'

export default {
  name: 'message-gibdd',
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    statusView () {
      switch (this.violationData.status) {
        case 'DIRECTING_TO_GIBDD':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            component: ['RCI_SPECIALIST'].includes(this.isRoad)
              ? GenerateMessage
              : StatusTask
          }
        default:
          return {
            changeDate: false,
            actionsView: false,
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Выполнено',
            component: StatusTask
          }
      }
    }
  },
  methods: {
    ...mapActions('violation', ['changeStatusViolation'])
  }
}
</script>
