import moment from 'moment'
export default {
  violationListTable (state, getters, rootState, rootGetters) {
    return state.violations.map(el => {
      const streetHouse = `${el.street || ''} ${el.house || ''}`
      return {
        id: el.id,
        favouriteLevel: el.favouriteLevel,
        importanceLevel: el.importanceLevel,
        number: el.number,
        inspectionObjectCode: rootGetters['directory/inspectionObjectInSourceCode']
          .find(io => io.code === el.inspectionObjectCode)?.name,
        violationSourceCode: rootState.directory.violationType
          .find(io => io.code === el.violationTypeCode)?.name,
        intruder: el.violator?.legalName || el.violator?.fullName,
        phone: el.violator?.phone,
        address: streetHouse,
        street: el.street,
        house: el.house,
        createdAt: moment(el.createdAt).format('DD.MM.YY'),
        executionTerm: el.executionTerm ? moment(el.executionTerm).format('DD.MM.YY') : '',
        status: rootState.directory.statuses
          .find(status => status.code === el.status)?.name,
        statusCode: el.status
      }
    })
  },
  violationSourceCode (state) {
    return state.violationData.violationSourceCode?.toLowerCase().replaceAll('_', '-')
  }
}
