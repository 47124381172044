<template>
  <section
    class="px-8"
  >
    <rir-tabs
      :items="routerList"
      :value="$route.name"
      @change="changeRoute"
      class="mb-5"
    />
    <router-view
      :key="$route.name"
    />
  </section>
</template>

<script>
import mixinLayout from '@/layout/mixins/mixinLayout'

export default {
  name: 'directories',
  mixins: [mixinLayout],
  mounted () {
    this.changeTitlePage('Справочники')
  },
  data: () => ({
    routeName: 'inspection-object',
    routerList: [
      {
        id: 'inspection-object',
        value: 'Объекты контроля'
      },
      {
        id: 'violation-type',
        value: 'Виды нарушений'
      },
      {
        id: 'violators',
        value: 'Ответственные за дороги'
      },
      {
        id: 'info-source',
        value: 'Источник информации'
      }
    ]
  }),
  beforeRouteUpdate (to, from, next) {
    if (to.name === 'directories') {
      next({ name: 'inspection-object' })
      this.routeName = 'inspection-object'
    } else {
      next()
    }
  },
  methods: {
    changeRoute (val) {
      if (val !== this.$route.name) { this.$router.push(({ name: val })) }
    }
  }
}
</script>
