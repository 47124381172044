<template>
  <section class="d-flex flex-column">
    <rir-accordion class="mb-xs-5">
      <div slot="activator">
        <div class="d-flex flex-column">
          <h2 class="titanic--text melia mb-xs-2">Результат устранения нарушения</h2>
          <span class="d-flex align-center">
          <rir-icon
            class="mr-xs-2"
            :fill="statusView.photo.iconColor"
            :icon="statusView.photo.icon"
          />
          <span class="terminator--text amphiris anie">{{ statusView.photo.status }}</span>
        </span>
        </div>
      </div>
      <component v-if="statusView.photo.component" :is="statusView.photo.component" />
    </rir-accordion>
    <rir-accordion
      v-if="statusView.generateDoc"
    >
      <div slot="activator">
        <div class="d-flex flex-column">
          <h2 class="titanic--text melia mb-xs-2">Проверка устранения нарушения</h2>
          <span class="d-flex align-center">
          <rir-icon
            class="mr-xs-2"
            :fill="statusView.docs.iconColor"
            :icon="statusView.docs.icon"
          />
          <span class="terminator--text amphiris anie">{{ statusView.docs.status }}</span>
        </span>
        </div>
      </div>
      <component v-if="statusView.docs.component" :is="statusView.docs.component" />
    </rir-accordion>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UploadPhoto from '@/views/road/steps/EliminationViolation/UploadPhoto'
import StatusTask from '@/views/road/steps/EliminationViolation/StatusTask'
import GenerateTask from '@/views/road/steps/EliminationViolation/GenerateTask'

export default {
  name: 'elimination-violation',
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapGetters('session', ['isRoad']),
    statusView () {
      switch (this.violationData.status) {
        case 'INSPECTING_ELIMINATION_VIOLATION':
          return {
            generateDoc: this.checkPhoto,
            photo: {
              iconColor: 'rocky',
              icon: 'in-progress',
              status: 'В процессе',
              component: UploadPhoto
            },
            docs: {
              iconColor: 'rocky',
              icon: 'in-progress',
              status: 'В процессе',
              component: ['RCI_SPECIALIST'].includes(this.isRoad)
                ? GenerateTask : StatusTask
            }
          }
        default:
          return {
            generateDoc: true,
            photo: {
              iconColor: 'matrix',
              icon: 'selected',
              status: 'Завершено',
              component: UploadPhoto
            },
            docs: {
              iconColor: 'matrix',
              icon: 'selected',
              status: 'Завершено',
              component: StatusTask
            }
          }
      }
    },
    checkPhoto () {
      return this.violationModel.attachments.some(file => file.type === '09-12')
    }
  }
}
</script>
