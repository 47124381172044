<template>
  <section>
    <form-generate-act :is-read="isRead"/>
    <rir-drag-upload-file
      v-if="!isRead"
      :count-file="1"
      :count-line="1"
      v-model="attachAct"
      :upload-url="$root.$uploadServer"
      :params-body="{
        code: 'ROAD_COVER_INSPECTION',
        type: '09-02'
      }"
      :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
    <div class="row row--no-grep mt-xs-5">
      <div class="col-md-4-12 mb-0 flex-column pl-xs-0">
        <h2 class="titanic--text melia mb-xs-5">Фототаблица №1</h2>
        <div class="terminator--text kleodora anie mb-xs-5">Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему</div>
        <rir-button
          @click="generatePhotoTable"
          :loading="isLoadingPhoto"
          class="memento godfather--text">Сформировать документ</rir-button>
        <rir-drag-upload-file
          class="mt-xs-5"
          v-if="!isRead"
          :count-file="1"
          :count-line="1"
          v-model="attachPhotoTable"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'ROAD_COVER_INSPECTION',
            type: '09-03'
          }"
          :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
      </div>
      <div class="col-md-4-12 mb-0 flex-column">
        <h2 class="titanic--text melia mb-xs-5">Предостережение</h2>
        <div class="terminator--text kleodora anie mb-xs-5">Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему</div>
        <rir-button
          :loading="isGenerateDoc"
          @click="generateWarning"
          class="memento godfather--text">Сформировать документ</rir-button>
        <rir-drag-upload-file
          class="mt-xs-5"
          v-if="!isRead"
          :count-file="1"
          :count-line="1"
          v-model="attachWarning"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'ROAD_COVER_INSPECTION',
            type: '09-04'
          }"
          :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
      </div>
    </div>
    <rir-button
      v-if="!isRead"
      :loading="isSendDocs"
      class="mt-xs-5"
      @click="changeStatus"
      :disabled="checkAllFile">Отправить документы</rir-button>
  </section>
</template>

<script>
import PrintPage from '@/plugins/mixins/PrintPage'
import FormGenerateAct from '@/views/road/steps/SigningWarningWithDirector/FormGenerateAct'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'form-generate-docs',
  components: { FormGenerateAct },
  mixins: [PrintPage],
  mounted () {
    this.attachAct = this.violationModel.attachments.filter(file => file.type === '09-02')
    this.attachPhotoTable = this.violationModel.attachments.filter(file => file.type === '09-03')
    this.attachWarning = this.violationModel.attachments.filter(file => file.type === '09-04')
  },
  data: () => ({
    isLoadingPhoto: false,
    isSendDocs: false,
    isGenerateDoc: false,
    attachAct: [],
    attachPhotoTable: [],
    attachWarning: []
  }),
  props: {
    isRead: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapGetters(['GET_BLOB', 'POST', 'GET']),
    checkAllFile () {
      return !this.attachAct.length || !this.attachPhotoTable.length || !this.attachWarning.length
    }
  },
  methods: {
    ...mapMutations('violation', ['changeViolationAttachments']),
    ...mapActions('violation', ['updateViolation', 'changeStatusViolation']),
    async changeStatus () {
      this.isSendDocs = true;
      [
        {
          type: '09-02',
          fileEntity: 'attachAct'
        },
        {
          type: '09-03',
          fileEntity: 'attachPhotoTable'
        },
        {
          type: '09-04',
          fileEntity: 'attachWarning'
        }].forEach(file => {
        this.changeViolationAttachments({
          clearType: file.type,
          files: this[file.fileEntity]
        })
      })
      await this.updateViolation({
        model: this.violationModel
      })
      await this.changeStatusViolation({
        decision: 'APPROVE'
      })
      this.isSendDocs = false
    },
    async generateWarning () {
      this.isGenerateDoc = true
      const { data } = await this.GET_BLOB(`violation/road-cover-inspection/${this.violationData.id}/warning`)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Предостережение.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.isGenerateDoc = false
    },
    async generatePhotoTable () {
      this.isLoadingPhoto = true
      // const info = await this.GET(`violation/road-cover-inspection/${this.violationData.id}/photo-data`)
      const data = await this.POST('common/image/generate-watermarks', {
        id: this.violationData.id,
        attachmentTypeCode: '09-11'
      })
      this.isLoadingPhoto = false
      this.printPhotoTable(data)
    }
  }
}
</script>
