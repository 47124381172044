export default {
  setProperty: (state, payload) => {
    const { name, value } = payload
    state[name] = value
  },
  setPropertyModel: (state, payload) => {
    const { model, name, value } = payload
    state[model][name] = value
  },
  saveDataModelInResponse: (state, payload) => {
    const { modelState, data } = payload
    Object.keys(state[modelState]).forEach(key => {
      state[modelState][key] = data[key]
    })
  },
  removeEntityArray: (state, payload) => {
    const { name, value } = payload
    const index = state[name].findIndex(el => el.id === value)
    state[name].splice(index, 1)
  }
}
