import Entity from '@/models/Entity'
import getEnv from '@/plugins/env'
export default class rViolationTypeEntity extends Entity {
  id = null
  name = null
  code = null
  legalAct = null

  static config = {
    formFields: [
      {
        title: 'Название',
        column: 'name',
        validate: [
          {
            type: 'require',
            text: 'Обязательное поле'
          }
        ]
      },
      {
        title: 'Выдержка из закона',
        column: 'legalAct',
        validate: []
      }
    ],
    link: `${getEnv('VUE_APP_API_SERVER')}violation/ref/violation-type/`
  }

  constructor (data = {}) {
    super()
    this.setDataEntity(data)
  }

  static getClassName () { return 'rViolationTypeEntity' }
  getClassName () { return rViolationTypeEntity.getClassName() }
}
