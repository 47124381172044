<template>
  <div id="app" class="app">
    <rir-modal ref="modal"/>
    <rir-notification ref="notification" />
    <component :is="$route.meta.layout" />
  </div>
</template>
<script>
import getEnv from '@/plugins/env'
import Vue from 'vue'

export default {
  name: 'app',
  mounted () {
    Vue.prototype.$modal = this.$refs.modal.openModal
    Vue.prototype.$notification = this.$refs.notification.pushNotification
    Vue.prototype.$uploadServer = `${getEnv('VUE_APP_API_SERVER')}upload-by-code`
  },
  watch: {
    $route: function () {
      sessionStorage.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
.app{
  height: 100%;
}
</style>
