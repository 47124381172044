<template>
  <section class="d-flex flex-column">
    <form-generate-act :is-read="!isEdit"/>
    <rir-drag-upload-file
      v-if="isEdit"
      :count-file="1"
      :count-line="1"
      v-model="attachAct"
      :upload-url="$root.$uploadServer"
      :params-body="{
        code: 'ROAD_COVER_INSPECTION',
        type: '09-05'
      }"
      :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
    <div class="row row--no-grep mt-xs-5">
      <div class="col-md-6-12 flex-column pl-xs-0">
        <h2 class="titanic--text melia mb-xs-5">Фототаблица №2</h2>
        <div class="terminator--text kleodora anie mb-xs-5">Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему</div>
        <rir-button
          @click="generatePhotoTable"
          :loading="isLoadingPhoto"
          class="memento godfather--text mb-xs-5">Сформировать документ</rir-button>
        <rir-drag-upload-file
          v-if="isEdit"
          :count-file="1"
          :count-line="1"
          v-model="attachPhotoTable"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'ROAD_COVER_INSPECTION',
            type: '09-06'
          }"
          :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
      </div>
      <div class="col-md-6-12 flex-column pr-xs-0">
        <h2 class="titanic--text melia mb-xs-5">Мотивированное представление</h2>
        <div class="terminator--text kleodora anie mb-xs-5">Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему</div>
        <rir-button
          @click="generateMotivation"
          class="memento godfather--text mb-xs-5">Сформировать документ</rir-button>
        <rir-drag-upload-file
          v-if="isEdit"
          :count-file="1"
          :count-line="1"
          v-model="attachWarning"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'ROAD_COVER_INSPECTION',
            type: '09-07'
          }"
          :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
      </div>
    </div>
    <rir-button
      v-if="isEdit"
      @click="changeStatus(true)"
      :disabled="checkAllFile">Закончить работы по нарушению</rir-button>
    <rir-button
      v-if="isEdit"
      class="mt-xs-5"
      color="error"
      @click="changeStatus(false)"
      :disabled="checkAllFile">Направить в ГИБДД</rir-button>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import FormGenerateAct from '@/views/road/steps/EliminationViolation/FormGenerateAct'
import PrintPage from '@/plugins/mixins/PrintPage'

export default {
  name: 'generate-task',
  components: { FormGenerateAct },
  mounted () {
    this.attachAct = this.violationModel.attachments.filter(file => file.type === '09-05')
    this.attachPhotoTable = this.violationModel.attachments.filter(file => file.type === '09-06')
    this.attachWarning = this.violationModel.attachments.filter(file => file.type === '09-07')
  },
  mixins: [PrintPage],
  data: () => ({
    isLoadingPhoto: false,
    attachAct: [],
    attachPhotoTable: [],
    attachWarning: []
  }),
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapGetters(['GET_BLOB', 'POST', 'GET']),
    isEdit () {
      return this.violationData.status === 'INSPECTING_ELIMINATION_VIOLATION'
    },
    checkAllFile () {
      return !this.attachAct.length || !this.attachPhotoTable.length || !this.attachWarning.length
    }
  },
  methods: {
    ...mapMutations('violation', ['changeViolationAttachments']),
    ...mapActions('violation', ['updateViolation', 'changeStatusViolation']),
    async generateMotivation () {
      const { data } = await this.GET_BLOB(`violation/road-cover-inspection/${this.violationData.id}/motivation`)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Мотивированное представление.odt') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async changeStatus (eliminated) {
      [
        {
          type: '09-05',
          fileEntity: 'attachAct'
        },
        {
          type: '09-06',
          fileEntity: 'attachPhotoTable'
        },
        {
          type: '09-07',
          fileEntity: 'attachWarning'
        }].forEach(file => {
        this.changeViolationAttachments({
          clearType: file.type,
          files: this[file.fileEntity]
        })
      })
      await this.updateViolation({
        model: {
          ...this.violationModel,
          eliminated
        }
      })
      await this.changeStatusViolation({
        decision: 'APPROVE'
      })
    },
    async generatePhotoTable () {
      this.isLoadingPhoto = true
      // const info = await this.GET(`violation/road-cover-inspection/${this.violationData.id}/photo-data`)
      const data = await this.POST('common/image/generate-watermarks', {
        id: this.violationData.id,
        attachmentTypeCode: '09-12'
      })
      this.isLoadingPhoto = false
      this.printPhotoTable(data)
    }
  }
}
</script>
