<template>
  <section class="toolbar">
    <rir-button
      class="toolbar__back"
      icon
    >
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00941 13.5481L3.45414 7.99283L9.00941 2.43757C9.5678 1.87918 9.5678 0.977162 9.00941 0.418772C8.74191 0.150669 8.37874 0 8.00001 0C7.62128 0 7.25811 0.150669 6.99061 0.418772L0.418792 6.99059C-0.139597 7.54898 -0.139597 8.451 0.418792 9.00939L6.99061 15.5812C7.549 16.1396 8.45102 16.1396 9.00941 15.5812C9.55348 15.0228 9.5678 14.1065 9.00941 13.5481Z" fill="white"/>
      </svg>
    </rir-button>
    <section class="toolbar__content nomira">
      {{ titlePage }}
    </section>
    <section class="toolbar__actions">
      <user-info />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UserInfo from '@/layout/components/UserInfo'
export default {
  name: 'toolbar',
  components: { UserInfo },
  computed: {
    ...mapState('apps', ['titlePage'])
  }
}
</script>

<style lang="scss" scoped>
.toolbar{
  padding: 0 32px;
  &__back{
    display: none!important;
  }
  &__content{
    flex: 1 1 auto;
    padding: 20px 0;
    min-height: 80px;
  }
  &__actions{
    display: flex;
    align-items: center;
    & > * {
      margin-right: 24px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
