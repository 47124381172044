<template>
  <section
    class="filters-list"
    :class="{ active: filterView }"
  >
    <div class="row">
      <div class="col-md-12-12">
        <a @click="toggleFilters" class="d-flex align-center">
          <rir-icon
            class="mr-1"
            icon="filter" />
          <span class="rocky--text amphiris">Показать фильтр</span>
          <rir-icon
            class="ml-1"
            size="12"
            icon="arrow-down" />
        </a>
      </div>
    </div>
    <div v-if="filterView">
      <div class="row">
        <div class="col-md-3-12" v-if="checkExecutor">
          <rir-select
            :items="inspectorList"
            label="Ответственный"
            text-value="fullName"
            id-value="id"
            select-all
            v-model="filters.executorId" />
        </div>
        <div class="col-md-3-12">
          <rir-select
            label="Статус нарушения"
            select-all
            :items="statusesInSourceCode"
            text-value="name"
            id-value="code"
            v-model="filters.statusCode"
          />
        </div>
        <div
          v-if="isPreventionCode"
          class="col-md-3-12">
          <rir-select
            label="Статус созвона"
            select-all
            :items="phoneCallStatus"
            text-value="name"
            id-value="code"
            v-model="filters.phoneCallStatusCode"
          />
        </div>
        <div class="col-md-3-12">
          <rir-date-picker
            period
            label="Период нарушения"
            is-time-zone
            v-model="filters.date"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3-12">
          <rir-select
            label="Объект контроля"
            select-all
            :items="inspectionObjectInSourceCode"
            text-value="name"
            id-value="code"
            v-model="filters.inspectionObjectCode" />
        </div>
        <div class="col-md-6-12">
          <rir-select
            :readonly="!filters.inspectionObjectCode"
            label="Вид нарушения"
            select-all
            :items="violationTypeIsObject"
            text-value="name"
            id-value="code"
            v-model="filters.violationTypeCode"
          />
        </div>
        <div class="col-md-3-12">
          <rir-input label="Поиск" v-model="filters.searchable">
            <rir-icon
              slot="before"
              icon="search" />
          </rir-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12-12">
          <rir-button @click="changeFilter">Применить фильтры</rir-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'filters-list',
  created () {
    if (sessionStorage[this.isPreventionCode ? 'filterPrev' : 'filterRoad']) {
      this.filters = JSON.parse(sessionStorage[this.isPreventionCode ? 'filterPrev' : 'filterRoad'])
    } else {
      const tz = new Date().getTimezoneOffset()
      const timeZone = Math.abs(tz)
      const hours = Math.floor(timeZone / 60)
      const minutes = timeZone - hours * 60
      const tzLocal = `Z${tz <= 0 ? '+' : '-'}${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`
      const dateLastWeek = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0, 0, 0, 0
      )
      dateLastWeek.setDate(dateLastWeek.getDate() - 7)
      this.filters.date = [
        `${dateLastWeek.toLocaleDateString('en-CA')}${tzLocal}`,
        `${new Date().toLocaleDateString('en-CA')}${tzLocal}`
      ]
    }
    this.$emit('input', this.filters)
  },
  data: () => ({
    filterView: false,
    filters: {
      executorId: null,
      inspectionObjectCode: null,
      violationTypeCode: null,
      statusCode: null,
      searchable: null,
      phoneCallStatusCode: null,
      date: [],
      size: 25,
      pageIndex: 0
    }
  }),
  watch: {
    'filters.inspectionObjectCode': function () {
      this.filters.violationTypeCode = null
      this.$emit('input', this.filters)
    }
  },
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapState('directory', ['profilesPrevention', 'profilesRoad', 'phoneCallStatus', 'violationType']),
    ...mapGetters('directory', ['inspectionObjectInSourceCode', 'statusesInSourceCode']),
    ...mapGetters('session', ['isPrevention', 'isRoad']),
    isPreventionCode () {
      if (this.violationData.violationSourceCode === null) {
        return this.$route.path.indexOf('prevention') !== -1
      }
      return this.violationData.violationSourceCode === 'PREVENTION'
    },
    inspectorList () {
      return this.isPreventionCode ? this.profilesPrevention : this.profilesRoad
    },
    checkExecutor () {
      return !['PREV_INSPECTOR', 'RCI_SPECIALIST'].includes(this.isPrevention || this.isRoad)
    },
    violationTypeIsObject () {
      return this.violationType
        .filter(vt => (vt.inspectionObjects.map(io => io.code)).includes(this.filters.inspectionObjectCode))
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  methods: {
    ...mapMutations('violation', ['setProperty']),
    changeFilter () {
      this.filters.pageIndex = 0
      sessionStorage[this.isPreventionCode ? 'filterPrev' : 'filterRoad'] = JSON.stringify(this.filters)
      this.setProperty({ name: 'violations', value: [] })
      this.$emit('input', this.filters)
      this.$emit('update')
    },
    toggleFilters () {
      this.filterView = !(this.filterView)
    }
  }
}
</script>

<style lang="scss">
.filters-list{
  &.active{
    & ~ .prevention-list__body{
      height: calc(100% - 226px);
    }
  }
}
</style>
