import Vue from 'vue'
import PrintReport from '@/views/print/PrintReport'
import store from '../../store'
import PrintPhotoTable from '@/views/print/PrintPhotoTable'

export default {
  data: () => ({
    windowPrint: null,
    instance: null
  }),
  methods: {
    printReport () {
      const ComponentClass = Vue.extend(PrintReport)
      this.instance = new ComponentClass({
        store,
        propsData: {
          beforeTypePhoto: this.violationData.violationSourceCode === 'PREVENTION' ? '08-01' : '09-11',
          afterTypePhoto: this.violationData.violationSourceCode === 'PREVENTION' ? '08-02' : '09-12'
        }
      })
      this.instance.$mount()
      this.initPagePrint()
    },
    printPhotoTable (photos) {
      const ComponentClass = Vue.extend(PrintPhotoTable)
      this.instance = new ComponentClass({
        propsData: {
          photos,
          executor: this.violationData.executorName
        }
      })
      this.instance.$mount()
      this.initPagePrint()
    },
    initPagePrint () {
      this.windowPrint = window.open('', '')
      this.windowPrint.document.write(this.instance.$el.innerHTML)
      this.windowPrint.document.addEventListener('DOMContentLoaded', this.ready)
      this.windowPrint.document.close()
    },
    ready () {
      setTimeout(() => {
        this.windowPrint.focus()
        this.windowPrint.print()
      }, 1000)
      // this.windowPrint.close()
    }
  }
}
