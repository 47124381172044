import Entity from '@/models/Entity'
import getEnv from '@/plugins/env'

export default class rViolationInfoSourceEntity extends Entity {
  id = null
  name = null
  code = null

  static config = {
    formFields: [
      {
        title: 'Название',
        column: 'name',
        validate: [
          {
            type: 'require',
            text: 'Обязательное поле'
          }
        ]
      }
    ],
    link: `${getEnv('VUE_APP_API_SERVER')}violation/ref/info-source/`
  }

  constructor (data = {}) {
    super()
    this.setDataEntity(data)
  }

  static getClassName () { return 'rViolationInfoSourceEntity' }
  getClassName () { return rViolationInfoSourceEntity.getClassName() }
}
