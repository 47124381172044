<template>
  <rir-cards-block>
    <a @click="$attrs.closeModal" class="d-flex align-center px-sm-6" slot="status">
      <rir-icon class="mr-2" icon="arrow-left" />
      Назад к списку нарушений
    </a>
    <div slot="title" class="d-flex flex-column">
      <span class="nomira mb-xs-3 px-sm-6">Новое нарушение</span>
      <div class="d-flex align-center px-sm-6">
        <rir-icon icon="calendar" class="mr-xs-2"/>
        <span class="mr-xs-4 kleodora titanic--text biqe">{{ nowDate.date }}</span>
        <rir-icon icon="clock"  class="mr-xs-2"/>
        <span class="kleodora kleodora titanic--text biqe">{{ nowDate.hour }}</span>
      </div>
    </div>
    <section class="row row--no-grep">
      <div class="col-md-6-12 pl-6">
        <rir-select
          v-if="isDepartmentHead"
          label="Инспектор"
          :items="profilesPrevention"
          id-value="id"
          text-value="fullName"
          v-model="model.executorId"
        />
      </div>
    </section>
    <section class="d-flex flex-column">
      <h2 class="melia mb-xs-4 px-sm-6">Фотографии нарушения</h2>
      <rir-drag-upload-file
        class="mb-xs-4 px-sm-6"
        :count-file="0"
        v-model="model.attachments"
        :upload-url="$root.$uploadServer"
        :params-body="{
          code: 'PREVENTION',
          type: '08-01'
        }"
        :accept-file="['.jpg', '.jpeg', '.png']"/>
      <violations-fields
        v-model="model"
        ref="fields"
        is-create/>
    </section>
    <section
      slot="actions"
      class="px-sm-6">
      <rir-button
        :loading="isLoading"
        @click="saveModel">Создать</rir-button>
    </section>
  </rir-cards-block>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import { InitState } from '@/store/modules/violation'
import ViolationsFields from '@/components/fields/ViolationsFields'
export default {
  name: 'prevention-create',
  components: { ViolationsFields },
  created () {
    this.model = JSON.parse(JSON.stringify({
      // eslint-disable-next-line new-cap
      ...InitState().violationModel,
      camera: this.profile?.camera,
      measuringTool: this.profile?.measurementTool
    }))
  },
  data: () => ({
    isLoading: false,
    model: null,
    nowDate: {
      date: moment().format('DD MMMM YYYY'),
      hour: moment().format('HH:mm')
    }
  }),
  computed: {
    ...mapState('session', ['profile']),
    ...mapGetters('session', ['isPrevention']),
    ...mapState('directory', ['profilesPrevention']),
    isDepartmentHead () {
      return ['PREV_DEPARTMENT_HEAD'].includes(this.isPrevention)
    }
  },
  methods: {
    ...mapActions('violation', ['addViolation']),
    ...mapActions('directory', ['updateExecutor']),
    async saveModel () {
      this.$refs.fields.$v.$touch()
      if (this.$refs.fields.$v.$invalid) return
      this.isLoading = true
      try {
        const prevention = await this.addViolation({ model: this.model })
        if (this.isDepartmentHead && !!this.model.executorId) {
          await this.updateExecutor({
            id: prevention.id,
            executorId: this.model.executorId
          })
        }
        this.$attrs.closeModal(true)
      } catch {}
      this.isLoading = false
    }
  }
}
</script>
