<template>
  <section
    class="d-flex align-center">
    <lis-action-relationships
      :item="item"
    />
    <rir-button
      icon="edit"
      transparent
      @click="editEntity"
    />
    <rir-button
      :loading="isDelete"
      icon="delete"
      color="error"
      transparent
      @click="removeEntity"
    />
  </section>
</template>

<script>
import ModalCreateOrEditEntity from '@/components/directories/ModalCreateOrEditEntity'
import LisActionRelationships from '@/components/directories/relationships/ListActionRelationships'

export default {
  name: 'actions-directories-table',
  components: { LisActionRelationships },
  data: () => ({
    isDelete: false
  }),
  props: {
    item: {
      type: Object
    },
    emit: {
      type: Function
    }
  },
  methods: {
    async editEntity () {
      await this.$modal(ModalCreateOrEditEntity, {
        entity: this.item
      })
      this.emit({ actionType: 'EDIT', item: this.item })
    },
    async removeEntity () {
      this.isDelete = true
      try {
        await this.$axios
          .delete(`${this.item.constructor.config.link}${this.item.id}`)
        this.emit({ actionType: 'DELETE', item: this.item })
      } catch {
        this.isDelete = false
      }
    }
  }
}
</script>
