import mixinsCommit from '@/store/mixins/mixinsCommit'
import { InitState } from './index'
export default {
  ...mixinsCommit,
  unshiftList (state, payload) {
    state.violations.unshift(payload)
  },
  pushList (state, payload) {
    state.violations = state.violations.concat(payload)
  },
  setViolationSourceCode (state, payload) {
    state.violationData.violationSourceCode = payload
  },
  changeViolationStatus (state, payload) {
    state.violationData.status = payload
  },
  changeViolationExecutionTerm (state, payload) {
    state.violationModel.executionTerm = parseInt(payload)
  },
  changeViolationAttachments (state, payload) {
    const { clearType, files, isArray = false } = payload
    let attachments = state.violationModel.attachments.filter(file => file.type !== clearType)
    isArray ? attachments = attachments.concat(files) : attachments.push(files[0])
    state.violationModel.attachments = attachments
  },
  updateViolationAttachments (state, payload) {
    const { attachmentsList } = payload
    state.violationModel.attachments = attachmentsList
  },
  clearModel: (state, modelName) => {
    const newState = InitState()
    Object.keys(state[modelName]).forEach(key => {
      state[modelName][key] = newState[modelName][key]
    })
  }
}
