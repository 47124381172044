export default {
  methods: {
    async downloadFile (file) {
      const response = await fetch(file.url)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', decodeURI(file.filename)) // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
