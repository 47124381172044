export default class Entity {
  setDataEntity (data) {
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this[key] = data[key]
      }
    }
  }

  get DataApi () {
    const { formFields } = this.constructor.config
    return formFields.reduce((acc, f) => {
      acc[f.column] = this[f.column]
      return acc
    }, {})
  }
}
