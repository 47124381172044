<template>
  <section>
    <div
      v-if="isGlobalHead"
      class="row">
      <div class="col-md-4-12">
        <rir-select
          :items="typeModuleList"
          label="Модуль"
          text-value="title"
          id-value="code"
          v-model="typeModule" />
      </div>
    </div>
    <template
      v-if="!!typeModule">
      <div class="row">
        <div class="col-md-4-12">
          <rir-date-picker
            period
            label="Период нарушения"
            v-model="filter.period"/>
        </div>
        <div class="col-md-4-12">
          <rir-select
            :items="statusList"
            select-all
            label="Статус"
            text-value="name"
            id-value="code"
            v-model="filter.statusCode" />
        </div>
        <div class="col-md-4-12">
          <rir-select
            :items="inspectionObjectList"
            select-all
            label="Объект контроля"
            text-value="name"
            id-value="id"
            v-model="filter.inspectionObjectId" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4-12">
          <rir-select
            :items="executorList"
            select-all
            label="Исполнитель"
            text-value="fullName"
            id-value="id"
            v-model="filter.executorId" />
        </div>
        <div class="col-md-4-12">
          <rir-select
            select-all
            :items="infoSource"
            label="Источник нарушения"
            text-value="name"
            id-value="id"
            v-model="filter.infoSourceId" />
        </div>
        <div class="col-md-4-12">
          <rir-input
            v-model="filter.street"
            label="Улица"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12-12">
          <rir-select
            :items="typeReportList"
            label="Тип отчета"
            text-value="name"
            id-value="code"
            v-model="typeReport" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12-12">
          <rir-button @click="download">Скачать отчет</rir-button>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import Vue from 'vue'

import { mapGetters, mapState } from 'vuex'
import getEnv from '@/plugins/env'
const FILTER = {
  statusCode: null,
  inspectionObjectId: null,
  street: null,
  executorId: null,
  infoSourceId: null,
  period: []
}
export default {
  name: 'reports',
  created () {
    if (!this.isGlobalHead) {
      this.typeModule = this.isRoad
        ? 'road-cover-inspection'
        : 'prevention'
    }
  },
  data: () => ({
    typeModule: null,
    typeModuleList: [
      {
        title: 'Профилактика',
        code: 'prevention'
      },
      {
        title: 'Дорожный контроль',
        code: 'road-cover-inspection'
      }
    ],
    typeReport: 'status',
    typeReportList: [
      {
        code: 'status',
        name: 'Статусный отчет'
      },
      {
        code: 'full',
        name: 'Полный отчет'
      }
    ],
    filter: { ...FILTER }
  }),
  watch: {
    typeModule: function () {
      this.filter = { ...FILTER, period: this.filter.period }
    }
  },
  computed: {
    ...mapGetters('session', ['isGlobalHead', 'isRoad']),
    ...mapState('directory', [
      'statuses',
      'inspectionObject',
      'profilesPrevention',
      'profilesRoad',
      'infoSource'
    ]),
    processCode () {
      switch (this.typeModule) {
        case 'prevention':
          return 'PREVENTION'
        case 'road-cover-inspection':
          return 'ROAD_COVER_INSPECTION'
      }
      return null
    },
    statusList () {
      return this.statuses.filter(el => el.processCode === this.processCode)
    },
    inspectionObjectList () {
      return this.inspectionObject.filter(el => el.violationSourceCode === this.processCode)
    },
    executorList () {
      switch (this.typeModule) {
        case 'prevention':
          return this.profilesPrevention
        case 'road-cover-inspection':
          return this.profilesRoad
      }
      return []
    }
  },
  methods: {
    async download () {
      const { period, ...filter } = this.filter

      // need to pasre date to milliseconds
      filter.from = Date.parse(period[0])
      filter.to = Date.parse(period[1])
      Object.keys(filter).forEach(k => (!filter[k] && filter[k] !== undefined) && delete filter[k])
      const urlApi = new URL(`${getEnv('VUE_APP_API_SERVER')}violation/${this.typeModule}/report/${this.typeReport}/pdf`)
      urlApi.search = new URLSearchParams(filter).toString()
      const response = await fetch(urlApi.href, {
        headers: {
          Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
        }
      })
      if (response.status !== 200) {
        console.error(response.status)
        return
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', decodeURI('Report.pdf')) // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
}
</script>
