<template>
  <rir-badge
    style="max-width: 184px"
    :color="dataStatus.color"
    :text="dataStatus.shortName"/>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'status-item',
  props: {
    item: Object
  },
  computed: {
    ...mapState('directory', ['statuses']),
    dataStatus () {
      return this.statuses.find(s => s.code === this.item.statusCode)
    }
  }
}
</script>
