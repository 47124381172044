<template>
  <section
    style="width: 16px"
  >
    <rir-button
      :color="colorIcon"
      transparent
      :icon="iconFavorite"
      @click.native.stop="changeFavorite"/>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'favorite-item',
  mounted () {
    this.model = this.item.favouriteLevel
  },
  data: () => ({
    model: 0
  }),
  props: {
    item: Object
  },
  computed: {
    iconFavorite () {
      return this.model ? 'star_full' : 'star'
    },
    colorIcon () {
      return this.item.importanceLevel === 1 ? 'error' : 'primary'
    }
  },
  methods: {
    ...mapActions('violation', ['changeFavoriteItemList']),
    async changeFavorite () {
      this.model = this.model === 0 ? 1 : 0
      await this.changeFavoriteItemList({
        id: this.item.id,
        value: this.model
      })
    }
  }
}
</script>
