import Entity from '@/models/Entity'
import getEnv from '@/plugins/env'
import rViolationTypeEntity from '@/models/rViolationTypeEntity'
import rViolationSourceEntity from '@/models/rViolationSourceEntity'

export default class rInspectionObjectEntity extends Entity {
  id = null
  name = null
  code = null

  static config = {
    formFields: [{
      title: 'Название',
      column: 'name',
      validate: [
        {
          type: 'require',
          text: 'Обязательное поле'
        }
      ]
    }],
    relationships: {
      Available: {
        setting: {
          name: 'Виды нарушения'
        },
        parent: {
          name: 'Объект контроля',
          entity: rInspectionObjectEntity,
          column: 'inspectionObjectId'
        },
        hasOne: {
          name: 'Процесс',
          entity: rViolationSourceEntity,
          column: 'violationSourceId'
        },
        hasMany: {
          name: 'Вид нарушения',
          entity: rViolationTypeEntity,
          column: 'violationTypeId'
        }
      }
    },
    link: `${getEnv('VUE_APP_API_SERVER')}violation/ref/inspection-object/`
  }

  constructor (data = {}) {
    super()
    this.setDataEntity(data)
  }

  static getClassName () { return 'rInspectionObjectEntity' }
  getClassName () { return rInspectionObjectEntity.getClassName() }
}
