<template>
  <section class="d-flex flex-column" v-if="checkUploadFile">
    <rir-drag-upload-file
      :count-file="0"
      :count-line="3"
      v-model="files"
      :upload-url="$root.$uploadServer"
      :params-body="{
        code: 'ROAD_COVER_INSPECTION',
        type: '09-12'
      }"
    />
    <rir-button class="mt-xs-5" @click="uploadFiles">Сохранить</rir-button>
  </section>
  <rir-file-list
    v-else-if="files.length"
    :files="files"
    :count-line="3"
  />
  <section v-else class="d-flex flex-column">
    <div class="d-flex align-center">
      <rir-icon
        class="mr-xs-2"
        icon="waiting"
      />
      <span>Ожидается проверка нарушения</span>
    </div>
    <span class="pl-xs-6 titanic--text anie">Требуется проверка нарушения</span>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'upload-photo',
  mounted () {
    this.files = this.violationModel.attachments.filter(file => file.type === '09-12')
  },
  data: () => ({
    files: []
  }),
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    ...mapGetters('session', ['isRoad']),
    checkUploadFile () {
      return [
        'INSPECTING_ELIMINATION_VIOLATION'
      ].includes(this.violationData.status) &&
        this.isRoad === 'RCI_SPECIALIST'
    }
  },
  methods: {
    ...mapMutations('violation', ['changeViolationAttachments']),
    ...mapActions('violation', ['updateViolation']),
    async uploadFiles () {
      this.changeViolationAttachments({
        clearType: '09-12',
        files: this.files,
        isArray: true
      })
      await this.updateViolation({
        model: this.violationModel
      })
    }
  }
}
</script>
