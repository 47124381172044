<template>
  <section class="d-flex flex-column">
    <h2 class="titanic--text melia mb-4">Задание</h2>
    <section v-if="checkReturn" class="mb-4 d-flex flex-column">
      <div>{{ checkReturn.text }}</div>
      <div>{{ violationData.note }}</div>
    </section>
    <form-generate-task />
    <rir-drag-upload-file
      :count-file="1"
      :count-line="1"
      v-model="attach"
      :upload-url="$root.$uploadServer"
      :params-body="{
        code: 'ROAD_COVER_INSPECTION',
        type: '09-01'
      }"
      :accept-file="['.odt','.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"/>
    <rir-button
      :loading="isLoadTask"
      class="mt-xs-5"
      @click="changeStatus"
      :disabled="!attach.length">Отправить документ</rir-button>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import FormGenerateTask from '@/views/road/steps/SigningTaskWithBoss/FormGenerateTask'

export default {
  name: 'generate-task',
  components: { FormGenerateTask },
  mounted () {
    this.attach = this.violationModel.attachments.filter(file => file.type === '09-01')
  },
  data: () => ({
    isLoadTask: false,
    attach: []
  }),
  computed: {
    ...mapState('violation', ['violationData', 'violationModel']),
    checkReturn () {
      switch (this.violationData.status) {
        case 'RETURNED_FROM_SIGNING_TASK_BY_DEPARTMENT_HEAD':
          return {
            text: 'Вовзрат на доработку от начальника отдела'
          }
        case 'RETURNED_FROM_APPROVING_TASK_BY_VICE_MAYOR':
          return {
            text: 'Вовзрат на доработку от вице мэра'
          }
        default:
          return null
      }
    }
  },
  methods: {
    ...mapMutations('violation', ['changeViolationAttachments']),
    ...mapActions('violation', ['updateViolation', 'changeStatusViolation']),
    async changeStatus () {
      const item = this.$parent.$parent.$parent
      if (await item.isValid()) {
        this.isLoadTask = true
        this.changeViolationAttachments({
          clearType: '09-01',
          files: this.attach
        })
        await this.updateViolation({
          model: this.violationModel
        })
        await this.changeStatusViolation({
          decision: 'APPROVE'
        })
        this.isLoadTask = false
      } else {
        this.$notification({
          type: 'error',
          text: 'Требуется заполнить все поля нарушения'
        })
      }
    }
  }
}
</script>
