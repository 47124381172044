import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('apps', ['setProperty']),
    changeTitlePage (title) {
      this.setProperty({ name: 'titlePage', value: title })
    }
  }
}
