<template>
  <section style="display: flex; flex-direction: column">
    <div style="margin-bottom: 20px">Фототаблица.</div>
    <div
      style="position: relative; margin-bottom: 20px"
      v-for="(photo, index) in photos"
      :key="index"
    >
      <img style="width: 100%" :src="photo.url" />
      <div style="text-align: right">{{ executor }}</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'print-photo-table',
  props: {
    photos: {
      type: Array
    },
    info: {
      type: Object
    },
    date: {
      type: Number
    },
    street: {
      type: String
    },
    executor: {
      type: String
    }
  }
}
</script>
