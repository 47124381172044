import moment from 'moment'
moment.locale('ru')
export default {
  install: function (Vue, options) {
    Vue.filter('bytesToSize', (bytes = 0, decimals = 2) => {
      if (!bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    })
  }
}
