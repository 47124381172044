import Entity from '@/models/Entity'

export default class rViolationSourceEntity extends Entity {
  id = null
  name = null
  code = null

  constructor (data = {}) {
    super()
    this.setDataEntity(data)
  }

  static getClassName () { return 'rViolationSourceEntity' }
  getClassName () { return rViolationSourceEntity.getClassName() }
}
