import moment from 'moment'
moment.locale('ru')
export default {
  install: function (Vue) {
    Vue.filter('dateFull', (date) => date
      ? moment(date)
        .format('DD MMM YYYY г. HH:mm:ss') : '')
    Vue.filter('dateTextMonth', (date) => date
      ? moment(date)
        .format('DD MMMM YYYY') : '')
    Vue.filter('dateRuFormat', (date) => date
      ? moment(date)
        .format('DD.MM.YY') : '')
    Vue.filter('dateTime', (date) => date
      ? moment(date)
        .format('DD MMMM HH:mm') : '')
    Vue.filter('time', (date) => date
      ? moment(date)
        .format('HH:mm') : '')
    Vue.filter('timeText', (date) => date
      ? moment(date)
        .format('HH час. mm мин.') : '')
  }
}
