<template>
  <section style="display: flex; flex-direction: column">
    <div
      style="font-family: Golos, sans-serif; font-weight: bold; font-size: 32px; line-height: 36px; margin-bottom: 24px">
      Нарушение №{{violationData.number}}
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 12px">
      <svg style="margin-right: 8px" width="16px" height="16px" viewBox="0 0 16 16" fill="var(--rir-terminator)" xmlns="http://www.w3.org/2000/svg" class="svg-class">
        <path d="M5 8.0116C4.44772 8.0116 4 8.46017 4 9.01351V10.1267C4 10.6801 4.44772 11.1286 5 11.1286H6.88889C7.44117 11.1286 7.88889 10.6801 7.88889 10.1267V9.01351C7.88889 8.46017 7.44117 8.0116 6.88889 8.0116H5Z" fill="var(--rir-terminator)"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.88867 1.00557C5.88867 0.452232 5.44096 0.00366211 4.88867 0.00366211C4.33639 0.00366211 3.88867 0.452232 3.88867 1.00557V1.33229L2.55556 1.33233C1.14416 1.33233 0 2.47847 0 3.89256V12.9943C0 14.6543 1.34315 16 3 16H13C14.6569 16 16 14.6543 16 12.9943V4.33785C16 2.67783 14.6569 1.33212 13 1.33212H12.1113V1.00557C12.1113 0.452232 11.6636 0.00366211 11.1113 0.00366211C10.559 0.00366211 10.1113 0.452232 10.1113 1.00557V1.33207L5.88867 1.33222V1.00557ZM11.1113 3.33602L11.093 3.33586L4.9045 3.33607L4.88867 3.33619L4.87291 3.33607L2.55469 3.33615L2.54287 3.33608C2.24191 3.34284 2 3.58939 2 3.89256V5.00587H11.9612C12.5135 5.00587 12.9612 5.45444 12.9612 6.00778C12.9612 6.56112 12.5135 7.00969 11.9612 7.00969H2V12.9943C2 13.5476 2.44772 13.9962 3 13.9962H13C13.5523 13.9962 14 13.5476 14 12.9943V4.33785C14 3.78451 13.5523 3.33594 13 3.33594H11.1242L11.1113 3.33602Z" fill="var(--rir-terminator)"></path>
      </svg>
      <span
        style="font-family: Golos, sans-serif; margin-right: 16px; font-size: 13px; line-height: 16px">{{ nowDate.date }}</span>
      <svg style="margin-right: 8px" width="16px" height="16px" viewBox="0 0 17 18" fill="var(--rir-terminator)" xmlns="http://www.w3.org/2000/svg" class="svg-class">
        <path d="M2.80518 11.9999C1.14833 9.13012 2.13158 5.46057 5.00134 3.80372C7.87109 2.14686 11.5406 3.13012 13.1975 5.99987C13.8024 7.0476 14.0549 8.19898 13.9935 9.32099C13.9634 9.87246 14.386 10.3439 14.9375 10.3741C15.4889 10.4043 15.9604 9.98166 15.9906 9.4302C16.0725 7.93238 15.7344 6.39388 14.9295 4.99987C12.7204 1.17353 7.82768 -0.137471 4.00134 2.07167C0.174995 4.28081 -1.13601 9.17353 1.07313 12.9999C3.28227 16.8262 8.17499 18.1372 12.0013 15.9281C13.0623 15.3155 13.9319 14.4947 14.5858 13.5464C14.8994 13.0917 14.785 12.469 14.3303 12.1554C13.8756 11.8419 13.2529 11.9563 12.9394 12.411C12.4504 13.12 11.7999 13.735 11.0013 14.196C8.13158 15.8529 4.46204 14.8696 2.80518 11.9999Z" fill="var(--rir-terminator)"></path>
        <path d="M9.00087 4.99989C9.00087 4.44761 8.55315 3.99989 8.00087 3.99989C7.44858 3.99989 7.00087 4.44761 7.00087 4.99989V8.46471C7.00087 9.13341 7.33507 9.75788 7.89147 10.1288L10.4462 11.8319C10.9057 12.1383 11.5266 12.0141 11.8329 11.5546C12.1393 11.0951 12.0151 10.4742 11.5556 10.1678L9.00087 8.46471V4.99989Z" fill="var(--rir-terminator)"></path>
      </svg>
      <span
        style="font-family: Golos, sans-serif; margin-right: 16px; font-size: 13px; line-height: 16px">{{ nowDate.hour }}</span>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 32px">
      <svg style="margin-right: 8px" width="16px" height="16px" viewBox="0 0 16 16" fill="var(--rir-terminator)" xmlns="http://www.w3.org/2000/svg" class="svg-class">
        <path d="M8.00143 10C5.24 10 3.00143 7.76142 3.00143 5C3.00143 2.23858 5.24 0 8.00143 0C10.7629 0 13.0014 2.23858 13.0014 5C13.0014 7.76142 10.7629 10 8.00143 10ZM8.00143 8C9.65828 8 11.0014 6.65685 11.0014 5C11.0014 3.34315 9.65828 2 8.00143 2C6.34457 2 5.00143 3.34315 5.00143 5C5.00143 6.65685 6.34457 8 8.00143 8ZM13.7561 14.3616C14.1398 14.7457 14.1287 15.3577 13.7315 15.7287C13.3342 16.0996 12.7011 16.0889 12.3175 15.7048C10.0156 13.4003 6.21716 13.3362 3.83348 15.5616C3.81064 15.583 3.81064 15.583 3.788 15.6045C3.39471 15.9794 2.76156 15.975 2.37382 15.5948C1.98608 15.2146 1.99059 14.6024 2.38388 14.2276C2.4139 14.1991 2.4139 14.1991 2.44416 14.1707C5.6224 11.2034 10.687 11.2889 13.7561 14.3616Z" fill="var(--rir-terminator)"></path>
      </svg>
      <span
        style="font-family: Golos, sans-serif; margin-right: 16px; font-size: 13px; line-height: 16px">{{ violationData.executorName }}</span>
    </div>
    <div
      style="font-family: Golos, sans-serif; font-weight: bold; font-size: 20px; line-height: 24px; margin-bottom: 24px">
      Сведения о нарушении
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column;">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Объект контроля</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ inspectionObjectCode }}</span>
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column;">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Вид нарушения</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ inspectionViolationType }}</span>
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column; margin-right: 12px">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Улица</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.street }}</span>
      </div>
      <div style="display:flex; flex-direction: column; margin-right: 12px">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Дом/км</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.house }}</span>
      </div>
      <div style="display:flex; flex-direction: column; margin-right: 12px">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Широта</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.lat }}</span>
      </div>
      <div style="display:flex; flex-direction: column">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Долгота</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.lng }}</span>
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Описание нарушения</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.description }}</span>
      </div>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column; margin-right: 12px">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Срок исполнения</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.executionTerm | dateRuFormat }}</span>
      </div>
      <div style="display:flex; flex-direction: column;">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Источник информации</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationInfoSource }}</span>
      </div>
    </div>
    <template v-if="violator">
      <div
        style="font-family: Golos, sans-serif; font-weight: bold; font-size: 20px; line-height: 24px; margin-bottom: 24px">
        Нарушитель
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 24px">
        <div style="display:flex; flex-direction: column">
          <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">ФИО нарушителя</span>
          <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violator.fullName }}</span>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 24px">
        <div style="display:flex; flex-direction: column; margin-right: 12px">
          <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">E-mail</span>
          <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violator.email }}</span>
        </div>
        <div style="display:flex; flex-direction: column">
          <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Телефон</span>
          <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violator.phone }}</span>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 24px" v-if="!!violator.legalName">
        <div style="display:flex; flex-direction: column">
          <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Название юр.лица</span>
          <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.violator.legalName }}</span>
        </div>
      </div>
    </template>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column">
        <span style="font-family: Golos, sans-serif; font-size: 13px; line-height: 16px; margin-bottom: 4px; opacity: 0.72;">Дополнительная информация</span>
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">{{ violationModel.additionalInfo }}</span>
      </div>
    </div>
    <div
      style="font-family: Golos, sans-serif; font-weight: bold; font-size: 20px; line-height: 24px; margin-bottom: 24px;  page-break-before: always;">
      Результаты
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 24px">
      <div style="display:flex; flex-direction: column; width: 50%">
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">Фотографии до профилактики</span>
      </div>
      <div style="display:flex; flex-direction: column; width: 50%">
        <span style="font-family: Golos, sans-serif; font-size: 16px; line-height: 20px;">Фотографии после профилактики</span>
      </div>
    </div>
    <div
      style="display: flex; margin-bottom: 12px; orphans: 3;"
      v-for="p in maxPage"
      :key="p"
    >
      <div
        style="display:block; width: 46%; margin-right: 2%" v-if="photoBefore[p - 1]">
        <img :src="photoBefore[p - 1].url"  style="object-fit: contain; width: 100%; height: auto">
      </div>
      <div
        style="display:block; width: 46%; margin-left: 2%" v-if="photoAfter[p - 1]">
        <img  :src="photoAfter[p - 1].url"  style="object-fit: contain; width: 100%; height: auto">
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'print-report',
  props: {
    beforeTypePhoto: {
      type: String
    },
    afterTypePhoto: {
      type: String
    }
  },
  computed: {
    ...mapState('violation', ['violationModel', 'violationData']),
    ...mapState('directory', ['inspectionObject', 'violationType', 'infoSource']),
    nowDate () {
      return {
        date: moment(this.violationData.createdAt).format('DD MMMM YYYY'),
        hour: moment(this.violationData.createdAt).format('HH:mm')
      }
    },
    inspectionObjectCode () {
      if (!this.violationModel?.inspectionObjectCode) return ''
      return this.inspectionObject.find(el => el.code === this.violationModel.inspectionObjectCode).name
    },
    inspectionViolationType () {
      if (!this.violationModel?.violationTypeCode) return ''
      return this.violationType.find(el => el.code === this.violationModel.violationTypeCode).name
    },
    violationInfoSource () {
      if (!this.violationModel?.infoSourceCode) return ''
      return this.infoSource.find(el => el.code === this.violationModel.infoSourceCode).name
    },
    photoBefore () {
      return this.violationModel.attachments.filter(el => el.type === this.beforeTypePhoto)
    },
    photoAfter () {
      return this.violationModel.attachments.filter(el => el.type === this.afterTypePhoto)
    },
    maxPage () {
      return this.photoBefore.length > this.photoAfter.length ? this.photoBefore.length : this.photoAfter.length
    },
    violator () {
      return this.violationModel?.violator
    }
  }
}
</script>
