'use strict'

import Vue from 'vue'
import axios from 'axios'

import main from '../main'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  // paramsSerializer: function (params) {
  //   return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
  // }
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
    // config.headers['user-local'] = new Date().getTimezoneOffset() / 60 * -1
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    const { data } = error.response
    // Do something with response error
    if (data.message) {
      main.$root.$notification({
        type: 'error',
        text: data.message
      })
    }
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
