<template>
  <section class="d-flex flex-column">
    <h2 class="titanic--text melia mb-xs-2">Задание</h2>
    <form-generate-task
      v-if="isForm"
      is-read
      class="mb-xs-5"/>
    <div class="d-flex flex-column">
      <div class="d-flex align-center">
        <rir-icon
          :fill="statusType.iconColor"
          class="mr-xs-2"
          :icon="statusType.icon"
        />
        <span>{{ statusType.status }}</span>
      </div>
      <span class="pl-xs-6 titanic--text anie">{{ statusType.text }}</span>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FormGenerateTask from '@/views/road/steps/SigningTaskWithBoss/FormGenerateTask'

export default {
  name: 'status-task',
  components: { FormGenerateTask },
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    statusType () {
      switch (this.violationData.status) {
        case 'CREATING_TASK':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Ожидание задания'
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Готово к подписанию у начальника отдела и вице-мэра'
          }
      }
    },
    isForm () {
      return ['RCI_SPECIALIST'].includes(this.isRoad)
    }
  }
}
</script>
