<template>
  <rir-cards-block
    class="pa-6"
  >
    <div slot="title" class="d-flex align-center">
      <rir-icon
        icon="info"
        size="32"
        class="mr-4"
      />
      <span class="titanic--text nomira">{{ title }}</span>
    </div>
    <div class="d-flex flex-column">
      <span class="titanic--text clonia mb-6">{{ text }}</span>
      <rir-button class="mb-4" @click="$attrs.closeModal(true)">Подтверждаю</rir-button>
      <rir-button color="error" @click="$attrs.closeModal()">Отменить</rir-button>
    </div>
  </rir-cards-block>
</template>

<script>
export default {
  name: 'confirm',
  props: {
    title: {
      type: String,
      default: 'Внимание!'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
