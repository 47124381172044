import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const InitState = () => ({
  statuses: [],
  infoSource: [],
  inspectionObject: [],
  violationType: [],
  taskForm: [],
  taskSubject: [],
  profilesPrevention: [],
  profilesRoad: [],
  violators: [],
  phoneCallStatus: [],
  importanceLevel: []
})

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
}
