<template>
  <span>{{ street }}</span>
</template>

<script>

export default {
  name: 'street-item',
  props: {
    item: Object
  },
  computed: {
    street () {
      const { street, house } = this.item
      return `${street ? `${street}, ` : ''} ${house || ''}`
    }
  }
}
</script>
