export default {
  loadDirectories ({ commit, rootGetters }) {
    rootGetters.GET('common/ref/process-status')
      .then((response) => {
        commit('setProperty', { name: 'statuses', value: response })
      })
    rootGetters.GET('violation/ref/info-source')
      .then((response) => {
        commit('setProperty', { name: 'infoSource', value: response })
      })
    rootGetters.GET('violation/ref/inspection-object-form')
      .then((response) => {
        commit('setProperty', { name: 'inspectionObject', value: response })
      })
    rootGetters.GET('violation/ref/violation-type')
      .then((response) => {
        commit('setProperty', { name: 'violationType', value: response })
      })
    rootGetters.GET('violation/ref/road-cover-inspection/task-form')
      .then((response) => {
        commit('setProperty', { name: 'taskForm', value: response })
      })
    rootGetters.GET('violation/ref/road-cover-inspection/task-subject')
      .then((response) => {
        commit('setProperty', { name: 'taskSubject', value: response })
      })
    rootGetters.GET('violation/executor-profile/prevention')
      .then((response) => {
        commit('setProperty', { name: 'profilesPrevention', value: response })
      })
    rootGetters.GET('violation/executor-profile/road-cover-inspection')
      .then((response) => {
        commit('setProperty', { name: 'profilesRoad', value: response })
      })
    rootGetters.GET('violation/ref/road-cover-inspection/violator')
      .then((response) => {
        commit('setProperty', { name: 'violators', value: response })
      })
    rootGetters.GET('violation/ref/phone-call-status')
      .then((response) => {
        commit('setProperty', { name: 'phoneCallStatus', value: response })
      })
    rootGetters.GET('violation/ref/importance-level')
      .then((response) => {
        commit('setProperty', { name: 'importanceLevel', value: response })
      })
  },
  async updateExecutor ({ commit, rootGetters }, payload) {
    const { id, executorId } = payload
    await rootGetters.PUT('common/update-executor', { id, executorId })
  }
}
