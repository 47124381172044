import Vue from 'vue'
import VueRouter from 'vue-router'
import PreventionList from '@/views/prevention/PreventionList'
import DefaultLayout from '@/layout/DefaultLayout'
import PreventionItem from '@/views/prevention/PreventionItem'
import RoadItem from '@/views/road/RoadItem'
import RoadList from '@/views/road/RoadList'
import ErrorPage from '@/views/ErrorPage'
import getEnv from '@/plugins/env'
import Directories from '@/views/directories/Directories'
import rViolationTypeEntity from '@/models/rViolationTypeEntity'
import rInspectionObjectEntity from '@/models/rInspectionObjectEntity'
import EntityList from '@/views/directories/EntityList'
import rViolatorEntity from '@/models/rViolatorEntity'
import rViolationInfoSourceEntity from '@/models/rViolationInfoSourceEntity'
import Reports from '@/views/reports/Reports'

Vue.use(VueRouter)

const PREV_ROLE = [
  'PREV_INSPECTOR',
  'PREV_DEPARTMENT_HEAD'
]
const ROAD_ROLE = [
  'RCI_SPECIALIST',
  'RCI_DEPARTMENT_HEAD',
  'VICE_MAYOR',
  'DIRECTOR'
]
const DIRECTORIES_ROLE = [
  'RCI_DEPARTMENT_HEAD',
  'PREV_DEPARTMENT_HEAD'
]

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'prevention' }
  },
  {
    path: '/error',
    component: {
      render: (h) => h('router-view')
    },
    children: [
      {
        path: '',
        name: 'error-page',
        component: ErrorPage,
        meta: {
          layout: {
            render: (h) => h('router-view')
          },
          roles: []
        }
      }
    ]
  },
  {
    path: '/prevention',
    name: 'prevention',
    component: {
      render: (h) => h('router-view')
    },
    redirect: { name: 'prevention-list' },
    children: [
      {
        path: '',
        name: 'prevention-list',
        component: PreventionList,
        meta: {
          layout: DefaultLayout,
          roles: PREV_ROLE
        }
      },
      {
        path: ':violationId',
        name: 'prevention-item',
        component: PreventionItem,
        props: true,
        meta: {
          layout: DefaultLayout,
          roles: PREV_ROLE
        }
      }
    ]
  },
  {
    path: '/road',
    name: 'road',
    component: {
      render: (h) => h('router-view')
    },
    redirect: { name: 'road-list' },
    children: [
      {
        path: '',
        name: 'road-list',
        component: RoadList,
        meta: {
          layout: DefaultLayout,
          roles: ROAD_ROLE
        }
      },
      {
        path: ':violationId',
        name: 'road-item',
        component: RoadItem,
        props: true,
        meta: {
          layout: DefaultLayout,
          roles: ROAD_ROLE
        }
      }
    ]
  },
  {
    path: '/directories',
    name: 'directories',
    component: Directories,
    redirect: { name: 'inspection-object' },
    meta: {
      layout: DefaultLayout,
      roles: DIRECTORIES_ROLE
    },
    children: [
      {
        path: 'inspection-object',
        name: 'inspection-object',
        component: EntityList,
        meta: {
          layout: DefaultLayout,
          roles: DIRECTORIES_ROLE,
          entity: rInspectionObjectEntity
        }
      },
      {
        path: 'violation-type',
        name: 'violation-type',
        component: EntityList,
        meta: {
          layout: DefaultLayout,
          roles: DIRECTORIES_ROLE,
          entity: rViolationTypeEntity
        }
      },
      {
        path: 'violators',
        name: 'violators',
        component: EntityList,
        meta: {
          layout: DefaultLayout,
          roles: DIRECTORIES_ROLE,
          entity: rViolatorEntity
        }
      },
      {
        path: 'info-source',
        name: 'info-source',
        component: EntityList,
        meta: {
          layout: DefaultLayout,
          roles: DIRECTORIES_ROLE,
          entity: rViolationInfoSourceEntity
        }
      }
    ]
  },
  {
    path: '/reports',
    name: 'reports',
    component: {
      render: (h) => h('router-view')
    },
    redirect: { name: 'reports-view' },
    children: [
      {
        name: 'reports-view',
        path: '',
        component: Reports,
        meta: {
          layout: DefaultLayout
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: getEnv('BASE_URL'),
  routes
})
export default router
