import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const InitState = () => ({
  violations: [],
  violationData: {
    id: null,
    violationSourceCode: null,
    createdAt: null,
    executorName: null,
    number: null,
    status: null,
    note: null,
    emailSendingStatus: null
  },
  violationModel: {
    importanceLevel: 0,
    executorId: null,
    violator: {
      fullName: null,
      email: null,
      phone: null,
      legalName: null
    },
    street: null,
    house: null,
    executionTerm: null,
    inspectionObjectCode: null,
    violationTypeCode: null,
    lat: null,
    lng: null,
    description: null,
    camera: null,
    measuringTool: null,
    infoSourceCode: null,
    attachments: [],
    eliminated: false,
    additionalInfo: null,
    phoneCallStatusCode: null
  },
  task: {
    territory: null,
    startDate: null,
    finishDate: null,
    formCode: null,
    subjectCode: null
  },
  act1: {
    inspectionBorders: null,
    violationDescription: null
  },
  act2: {
    inspectionBorders: null,
    violationDescription: null
  }
})

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
}
