<template>
  <rir-accordion>
    <div slot="activator">
      <div class="d-flex flex-column">
        <h2 class="titanic--text melia mb-xs-2">Подготовка задания</h2>
        <span class="d-flex align-center">
          <rir-icon
            class="mr-xs-2"
            :fill="statusView.iconColor"
            :icon="statusView.icon"
          />
          <span class="terminator--text amphiris anie">{{ statusView.status }}</span>
        </span>
      </div>
    </div>
    <component v-if="statusView.component" :is="statusView.component" />
  </rir-accordion>
</template>

<script>
import GenerateTask from '@/views/road/steps/SigningTaskWithBoss/GenerateTask'
import StatusTask from '@/views/road/steps/SigningTaskWithBoss/StatusTask'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'signing-task-with-boss',
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    statusView () {
      switch (this.violationData.status) {
        /*
        * Создание задание */
        case 'CREATING_TASK':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            component: ['RCI_SPECIALIST'].includes(this.isRoad)
              ? GenerateTask : StatusTask
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Выполнено',
            component: StatusTask
          }
      }
    }
  }
}
</script>
