<template>
  <section class="sidebar">
    <div class="sidebar__title">
      <div class="crephusa alien--text">КНД</div>
      <div class="clymenti amelie--text">Личный кабинет</div>
    </div>
    <div class="sidebar__menu">
      <router-link
        v-if="isPrevention"
        active-class="active"
        :to="{name: 'prevention-list'}"
        class="sidebar__menu-item">
        <div>
          <rir-icon icon="profilactic"/>
          <span class="amphiris">Профилактика</span>
        </div>
      </router-link>
      <router-link
        v-if="isRoad"
        active-class="active"
        :to="{name: 'road-list'}"
        class="sidebar__menu-item">
        <div>
          <rir-icon icon="road-control"/>
          <span class="amphiris">Дорожный контроль</span>
        </div>
      </router-link>
      <router-link
        active-class="active"
        :to="{ name: 'reports' }"
        class="sidebar__menu-item">
        <div>
          <rir-icon icon="errand"/>
          <span class="amphiris">Отчеты</span>
        </div>
      </router-link>
      <router-link
        v-if="isGlobalHead"
        active-class="active"
        :to="{ name: 'directories' }"
        class="sidebar__menu-item">
        <div>
          <rir-icon icon="errand"/>
          <span class="amphiris">Справочники</span>
        </div>
      </router-link>
    </div>
    <div class="sidebar__logo"></div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import getEnv from '@/plugins/env'
export default {
  name: 'sidebar',
  data: () => ({
    version: `Версия: ${getEnv('VUE_APP_REV')}`
  }),
  computed: {
    ...mapGetters('session', ['isPrevention', 'isRoad', 'isGlobalHead'])
  }
}
</script>
