<template>
  <rir-accordion>
    <div slot="activator">
      <div class="d-flex flex-column">
        <h2 class="titanic--text melia mb-xs-2">Утверждение предостережения у начальника отдела</h2>
        <span class="d-flex align-center">
          <rir-icon
            class="mr-xs-2"
            :fill="statusView.iconColor"
            :icon="statusView.icon"
          />
          <span class="terminator--text amphiris anie">{{ statusView.status }}</span>
        </span>
      </div>
    </div>
    <component v-if="statusView.component" :is="statusView.component" />
  </rir-accordion>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SigningTask from '@/views/road/steps/SigningCautionByDepartmentDirector/SigningTask'
import StatusTask from '@/views/road/steps/SigningCautionByDepartmentDirector/StatusTask'

export default {
  name: 'signing-caution-by-department-director',
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    statusView () {
      switch (this.violationData.status) {
        case 'SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            // @TODO раньше делал директор по задаче LSCLOC-381 стал начальник
            component: ['RCI_DEPARTMENT_HEAD'].includes(this.isRoad)
              ? SigningTask : StatusTask
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Выполнено',
            component: StatusTask
          }
      }
    }
  }
}
</script>
