<template>
  <section class="prevention-list">
    <filters-list
      v-model="filters"
      @update="loadDataTable"
    />
    <div class="prevention-list__body">
      <div class="prevention-list__list" id="list">
        <rir-table
          ref="table"
          @selectRow="selectRow"
          select-event
          :headers="headersTable"
          :sort-list.sync="sorting"
          :items="violationListTable"
          single-sort
          :loading="isLoadPage"
        />

      </div>
      <div class="prevention-list__preview">
        <violation-view
          :loading="isLoadPage"
          :violationId="selectViolation" />
      </div>
    </div>
    <a
      @click="openAddModal"
      class="prevention-list__add">
      <rir-icon icon="add" fill="amelie" />
    </a>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import mixinLayout from '@/layout/mixins/mixinLayout'
import RoadCreate from '@/views/road/modal/RoadCreate'
import ViolationView from '@/components/card/ViolationView'
import FavoriteItem from '@/components/table/FavoriteItem'
import StatusItem from '@/components/table/StatusItem'
import FiltersList from '@/components/fields/FiltersList'
import StreetItem from '@/components/table/StreetItem'

export default {
  name: 'road-list',
  components: { FiltersList, ViolationView },
  mounted () {
    this.changeTitlePage('Дорожный контроль')
    this.setViolationSourceCode('ROAD_COVER_INSPECTION')
    this.loadDataTable()
    const objDiv = document.getElementById('list')
    objDiv.onscroll = () => {
      if ((objDiv.scrollTop + objDiv.clientHeight) >= objDiv.scrollHeight && !this.isLoadScroll) {
        this.isLoadScroll = true
        if (this.violationListTable.length > this.filters.pageIndex * this.filters.size) {
          this.filters.pageIndex++
          this.loadDataTable()
        } else {
          this.isLoadScroll = false
        }
      }
    }
  },
  mixins: [mixinLayout],
  data: () => ({
    isLoadPage: false,
    isLoadScroll: false,
    filters: null,
    sorting: [
      {
        column: 'executionTerm',
        direction: 'DESC'
      }
    ],
    headersTable: [
      {
        title: '',
        column: 'favourite',
        component: FavoriteItem
      },
      {
        title: '№',
        column: 'number',
        sort: true
      },
      {
        title: 'Адрес',
        column: 'street',
        component: StreetItem,
        sort: true
      },
      {
        title: 'Создано',
        column: 'createdAt',
        sort: true
      },
      {
        title: 'Срок',
        column: 'executionTerm',
        sort: true
      },
      {
        title: 'Статус',
        column: 'status',
        sort: true,
        component: StatusItem
      }
    ],
    selectViolation: null
  }),
  watch: {
    sorting: function () {
      this.filters.pageIndex = 0
      this.loadDataTable()
    }
  },
  computed: {
    ...mapGetters('directory', ['inspectionObjectInSourceCode', 'statusesInSourceCode']),
    ...mapGetters('violation', ['violationListTable'])
  },
  methods: {
    ...mapMutations('violation', ['setViolationSourceCode']),
    ...mapActions('violation', ['fetchViolationList']),
    async loadDataTable () {
      this.isLoadPage = !this.isLoadScroll
      const { date, ...filters } = this.filters
      const filter = {
        ...filters,
        from: null,
        to: null,
        sorting: null,
        size: this.filters.size,
        pageIndex: this.filters.pageIndex
      }
      if (date.length) {
        filter.from = date[0]
        filter.to = date[1]
      }
      if (this.sorting.length) {
        filter.sorting = this.sorting.map(s => `${s.column}:${s.direction}`).join(',')
      }

      await this.fetchViolationList(filter)
      this.selectViolation = null
      if (this.violationListTable.length) {
        this.selectViolation = this.violationListTable[0].id
        this.$refs.table.selectRowIndex = 0
      }
      this.isLoadPage = false
      this.isLoadScroll = false
    },
    async openAddModal () {
      const res = await this.$modal(RoadCreate)
      if (res) await this.loadDataTable()
    },
    selectRow (item) {
      this.selectViolation = item.id
    }
  }
}
</script>

<style lang="scss" scoped>
.prevention-list{
  height: calc(100vh - 80px)!important;
  &__body{
    height: calc(100% - 40px);
    width: 100%;
    padding: 0 0 0 32px;
    display: flex;
  }
  &__list{
    width: 644px;
    margin-right: 32px;
    height: 100%;
    overflow-y: auto;
    padding-right: 4px;
  }
  &__preview{
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
    background-color: var(--rir-rush);
    border-radius: 16px 0 0 0;
    padding: 20px 0;
  }
  &__add{
    position: fixed;
    bottom: 40px;
    left: 872px;
    height: 40px;
    width: 40px;
    background-color: var(--rir-rocky);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
