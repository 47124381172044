<template>
  <section>
    <div class="row">
      <div class="col-sm-6-12">
        <rir-form-item
          :error="$v.model.inspectionObjectCode.$error"
          :message="mesErr($v.model.inspectionObjectCode)"
        >
          <rir-select
            :readonly="readonly"
            label="Объект контроля"
            :items="inspectionObjectInSourceCode"
            id-value="code"
            text-value="name"
            v-model="model.inspectionObjectCode" />
        </rir-form-item>
      </div>
      <div
        class="col-sm-6-12"
      >
        <rir-form-item>
          <rir-select
            :readonly="readonly"
            label="Приоритет"
            :items="importanceLevel"
            id-value="value"
            text-value="name"
            v-model="model.importanceLevel" />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12-12">
        <rir-form-item
          :error="$v.model.violationTypeCode.$error"
          :message="mesErr($v.model.violationTypeCode)"
        >
          <rir-select
            :readonly="readonly"
            label="Вид нарушения"
            :items="violationTypeList"
            id-value="code"
            text-value="name"
            v-model="model.violationTypeCode"
            no-data-items="Требуется выбраться объект контроля"
          />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4-10">
        <rir-form-item
          :error="$v.model.street.$error"
          :message="mesErr($v.model.street)"
        >
          <rir-input
            :readonly="readonly"
            label="Улица"
            v-model="model.street" />
        </rir-form-item>
      </div>
      <div class="col-sm-2-10">
        <rir-form-item
          :error="$v.model.house.$error"
          :message="mesErr($v.model.house)"
        >
          <rir-input
            :readonly="readonly"
            label="Дом/км"
            v-model="model.house" />
        </rir-form-item>
      </div>
      <div class="col-sm-2-10">
        <rir-form-item
          :error="$v.model.lat.$error"
          :message="mesErr($v.model.lat)"
        >
          <rir-input
            :readonly="readonly"
            label="Широта"
            v-model="model.lat" />
        </rir-form-item>
      </div>
      <div class="col-sm-2-10">
        <rir-form-item
          :error="$v.model.lng.$error"
          :message="mesErr($v.model.lng)"
        >
          <rir-input
            :readonly="readonly"
            label="Долгота"
            v-model="model.lng" />
        </rir-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12-12">
        <rir-textarea
          :readonly="readonly"
          label="Описание нарушения"
          :rows="3"
          v-model="model.description"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6-12">
        <rir-input
          :readonly="readonly"
          label="Фотоаппарат/смартфон"
          v-model="model.camera" />
      </div>
      <div class="col-sm-6-12">
        <rir-input
          :readonly="readonly"
          label="Измерительная линейка"
          v-model="model.measuringTool" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6-12">
        <rir-form-item
          :error="$v.model.executionTerm.$error"
          :message="mesErr($v.model.executionTerm)"
        >
        <rir-date-picker
          :readonly="readonly || isChangeFinishDate"
          v-mask="'##.##.####, ##:##'"
          :start-date="startDate"
          is-time
          label="Срок исполнения"
          v-model="model.executionTerm" />
        </rir-form-item>
      </div>
      <div class="col-sm-6-12">
        <rir-select
          :readonly="readonly"
          label="Источник информации"
          :items="infoSource"
          id-value="code"
          text-value="name"
          v-model="model.infoSourceCode" />
      </div>
    </div>
    <div class="row" v-if="isChangeViolator">
      <div class="col-sm-12-12">
        <rir-select
          :readonly="readonly"
          label="Выберите организацию"
          :items="violatorsList"
          id-value="id"
          text-value="legalName"
          @change="changeViolator"
          v-model="violator"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12-12">
        <rir-input
          :readonly="readonly || isViolator"
          label="ФИО нарушителя"
          v-model="model.violator.fullName"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6-12">
        <rir-input
          :readonly="readonly || isViolator"
          label="E-mail"
          v-model="model.violator.email"
        />
      </div>
      <div class="col-sm-6-12">
        <rir-input
          :readonly="readonly || isViolator"
          label="Телефон"
          v-mask="'+7 (###) ###-##-##'"
          v-model="model.violator.phone"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12-12">
        <rir-input
          :readonly="readonly || isViolator"
          label="Название юр. лица"
          v-model="model.violator.legalName"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12-12">
        <rir-textarea
          :readonly="readonly"
          label="Дополнительная информация"
          :rows="3"
          v-model="model.additionalInfo"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, typeFloatOrNumber } from '@/plugins/vuelidate/customValidate'

export default {
  name: 'violations-fields',
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  created () {
    this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + 1)).toLocaleDateString('en-CA')
    this.model = JSON.parse(JSON.stringify({
      ...this.value,
      violator: this.value?.violator || {
        fullName: null,
        phone: null,
        email: null,
        legalName: null,
        note: null
      }
    }))
    if (this.violationData.violationSourceCode === 'ROAD_COVER_INSPECTION') {
      this.violator = this.violatorsList.find(el => el.legalName === this.model.violator.legalName)?.id || null
      this.isViolator = !!this.violator
    }
  },
  data: () => ({
    startDate: new Date(),
    model: null,
    isViolator: false,
    violator: null
  }),
  validations: {
    model: {
      inspectionObjectCode: {
        required: required('Обязательное поле')
      },
      violationTypeCode: {
        required: required('Обязательное поле')
      },
      executionTerm: {
        required: required('Обязательное поле')
      },
      street: {
        required: true
      },
      house: {
        required: true
      },
      lat: {
        typeFloatOrNumber: typeFloatOrNumber('Формат ХХ.ХХ')
      },
      lng: {
        typeFloatOrNumber: typeFloatOrNumber('Формат ХХ.ХХ')
      }
    }
  },
  props: {
    value: {
      type: Object
    },
    readonly: {
      type: Boolean
    },
    isCreate: {
      type: Boolean
    }
  },
  watch: {
    'model.inspectionObjectCode': function () {
      if (!this.violationTypeList.some(el => el.code === this.model.violationTypeCode)) {
        this.model.violationTypeCode = null
      }
    },
    model: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  computed: {
    ...mapGetters('directory', ['inspectionObjectInSourceCode', 'violatorsList']),
    ...mapState('directory', ['infoSource', 'violationType', 'importanceLevel']),
    ...mapState('violation', ['violationData']),
    isRoadViolation () {
      return this.violationData.violationSourceCode === 'ROAD_COVER_INSPECTION'
    },
    isChangeViolator () {
      return this.isRoadViolation &&
        (this.violationData.status === 'CREATING_TASK' || this.isCreate)
    },
    isChangeFinishDate () {
      return !this.isCreate && !['CREATING_TASK', 'CREATING_DRAFT'].includes(this.violationData.status)
    },
    violationTypeList () {
      return this.violationType.filter(vt => {
        return vt.inspectionObjects.some(el =>
          el.code === this.model.inspectionObjectCode && el.violationSourceCode === this.violationData.violationSourceCode)
      })
    }
  },
  methods: {
    changeViolator (violator) {
      this.isViolator = !!violator.id
      if (this.isViolator) {
        const { id, ...attrs } = violator
        this.model = { ...this.model, violator: attrs }
      } else {
        this.model = {
          ...this.model,
          violator: {
            email: null,
            fullName: null,
            legalName: null,
            phone: null,
            note: null
          }
        }
      }
    }
  }
}
</script>
