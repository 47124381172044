<template>
  <section class="user">
    <a @click="openSettings" v-if="checkSettings">
      <rir-icon icon="settings" fill="titanic" :size="20"/>
    </a>
    <a @click="logoutSystem">
      <rir-icon icon="exit" fill="titanic" :size="20"/>
    </a>
    <div class="user__avatar">
      <rir-icon
        fill="amelie"
        icon="avatar" />
    </div>
    <span>{{ infoName }}</span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import UserSettings from '@/components/modal/UserSettings'
export default {
  name: 'user-info',
  computed: {
    ...mapGetters('session', ['infoName', 'isPrevention', 'isRoad']),
    checkSettings () {
      return ['PREV_INSPECTOR', 'RCI_SPECIALIST'].includes(this.isPrevention || this.isRoad)
    }
  },
  methods: {
    openSettings () {
      this.$modal(UserSettings)
    },
    logoutSystem () {
      this.$router.replace({ name: 'home' })
      this.$keycloak.logoutFn()
    }
  }
}
</script>

<style lang="scss" scoped>
.user{
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--rir-alien);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }
  & > span {
    font-weight: 500;
    color: var(--rir-titanic);
  }
  & > a {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
}
</style>
