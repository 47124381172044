export default class ListResponse {
  data = []
  nextPage = null
  entity = null

  constructor (data, EntityClass) {
    console.log(data)
    this.entity = EntityClass
    this.data = this.dataList(data)
  }

  nextData (data) {
    this.data = this.data.concat(data)
  }

  dataList (list) {
    return list
      .map(e => new this.entity(e))
  }

  spliceEntity (entity) {
    this.data.splice(this.data.findIndex(d => d === entity), 1)
  }
}
