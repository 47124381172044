<template>
  <section class="d-flex flex-column">
    <form-generate-docs
      v-if="isFormDocs"
      class="mb-5"
      is-read />
    <div class="d-flex align-center">
      <rir-icon
        :fill="statusType.iconColor"
        class="mr-xs-2"
        :icon="statusType.icon"
      />
      <span>{{ statusType.status }}</span>
    </div>
    <span class="pl-xs-6 titanic--text anie">{{ statusType.text }}</span>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FormGenerateDocs from '@/views/road/steps/SigningWarningWithDirector/FormGenerateDocs'

export default {
  name: 'status-task',
  components: { FormGenerateDocs },
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    isFormDocs () {
      return ['RCI_SPECIALIST'].includes(this.isRoad)
    },
    statusType () {
      switch (this.violationData.status) {
        case 'FIXING_VIOLATION':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Ожидание пакета документов'
          }
        case 'RETURNED_FROM_SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Возврат на доработку от директора'
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Отправлено директору департамента на утверждение'
          }
      }
    }
  }
}
</script>
