<template>
  <section class="d-flex flex-column">
    <h2 class="titanic--text melia mb-xs-4">Предостережение</h2>
    <rir-button
      @click="downloadFile(warningFile)"
      class="memento godfather--text">Скачать документ</rir-button>
    <div class="row row--no-grep mt-xs-4 px-0">
      <div class="col-md-12-12 px-0 flex-column">
        <div
          style="width: 500px"
          class="terminator--text kleodora anie mb-xs-5">
          Скачайте документ, проверьте. Если замечаний нет, то утвердите. Если документ требует доработки, то оставьте комментарий по правкам и отправьте предостережение на доработку
        </div>
        <rir-button
          :disabled="!!note"
          @click="approveTask">Утвердить документ</rir-button>
      </div>
    </div>
    <div class="row row--no-grep">
      <div class="col-md-12-12 px-0 flex-column">
        <rir-textarea
          style="width: 710px"
          label="Комментарии"
          class="mb-xs-6"
          :rows="6"
          v-model="note"/>
        <rir-button
          :disabled="!note || note.length < 10"
          color="error"
          @click="returnTask">Отправить на доработку</rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import downloadFile from '@/plugins/mixins/downloadFile'
import Confirm from '@/components/modal/Confirm'

export default {
  name: 'signing-task',
  mixins: [downloadFile],
  mounted () {},
  data: () => ({
    note: null
  }),
  computed: {
    ...mapState('violation', ['violationModel']),
    warningFile () {
      return this.violationModel.attachments.find(file => file.type === '09-04')
    }
  },
  methods: {
    ...mapMutations('violation', ['changeViolationAttachments']),
    ...mapActions('violation', ['updateViolation', 'changeStatusViolation']),
    returnTask () {
      this.changeStatusViolation({
        decision: 'RETURN',
        note: this.note
      })
    },
    async approveTask () {
      console.log('approveTask', this.violationModel)
      const violator = this.violationModel.violator
      let res = !violator || !violator.email
      if (res) {
        res = await this.$modal(Confirm, {
          text: 'E-mail нарушителя не заполнен, все равно продолжить?'
        }, {
          width: '350px'
        })
      }
      if (res) {
        await this.changeStatusViolation({
          decision: 'APPROVE'
        })
      }
    }
  }
}
</script>
