var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('form-generate-act',{attrs:{"is-read":_vm.isRead}}),(!_vm.isRead)?_c('rir-drag-upload-file',{attrs:{"count-file":1,"count-line":1,"upload-url":_vm.$root.$uploadServer,"params-body":{
      code: 'ROAD_COVER_INSPECTION',
      type: '09-02'
    },"accept-file":['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']},model:{value:(_vm.attachAct),callback:function ($$v) {_vm.attachAct=$$v},expression:"attachAct"}}):_vm._e(),_c('div',{staticClass:"row row--no-grep mt-xs-5"},[_c('div',{staticClass:"col-md-4-12 mb-0 flex-column pl-xs-0"},[_c('h2',{staticClass:"titanic--text melia mb-xs-5"},[_vm._v("Фототаблица №1")]),_c('div',{staticClass:"terminator--text kleodora anie mb-xs-5"},[_vm._v("Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему")]),_c('rir-button',{staticClass:"memento godfather--text",attrs:{"loading":_vm.isLoadingPhoto},on:{"click":_vm.generatePhotoTable}},[_vm._v("Сформировать документ")]),(!_vm.isRead)?_c('rir-drag-upload-file',{staticClass:"mt-xs-5",attrs:{"count-file":1,"count-line":1,"upload-url":_vm.$root.$uploadServer,"params-body":{
          code: 'ROAD_COVER_INSPECTION',
          type: '09-03'
        },"accept-file":['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']},model:{value:(_vm.attachPhotoTable),callback:function ($$v) {_vm.attachPhotoTable=$$v},expression:"attachPhotoTable"}}):_vm._e()],1),_c('div',{staticClass:"col-md-4-12 mb-0 flex-column"},[_c('h2',{staticClass:"titanic--text melia mb-xs-5"},[_vm._v("Предостережение")]),_c('div',{staticClass:"terminator--text kleodora anie mb-xs-5"},[_vm._v("Сформируйте документ, проверьте его, при необходимости исправьте, подпишите и загрузите обратно в систему")]),_c('rir-button',{staticClass:"memento godfather--text",attrs:{"loading":_vm.isGenerateDoc},on:{"click":_vm.generateWarning}},[_vm._v("Сформировать документ")]),(!_vm.isRead)?_c('rir-drag-upload-file',{staticClass:"mt-xs-5",attrs:{"count-file":1,"count-line":1,"upload-url":_vm.$root.$uploadServer,"params-body":{
          code: 'ROAD_COVER_INSPECTION',
          type: '09-04'
        },"accept-file":['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']},model:{value:(_vm.attachWarning),callback:function ($$v) {_vm.attachWarning=$$v},expression:"attachWarning"}}):_vm._e()],1)]),(!_vm.isRead)?_c('rir-button',{staticClass:"mt-xs-5",attrs:{"loading":_vm.isSendDocs,"disabled":_vm.checkAllFile},on:{"click":_vm.changeStatus}},[_vm._v("Отправить документы")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }