import Vue from 'vue'
import App from './App.vue'
import './plugins/axios'
import './registerServiceWorker'
import RirLib from './plugins/RirLib'
import router from './router'
import store from './store'
import './assets/scss/main.scss'
import Vuelidate from 'vuelidate'
import filterDate from '@/plugins/filters/filterDate'
import filterNumber from '@/plugins/filters/filterNumber'
import filterString from '@/plugins/filters/filterString'

import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import getEnv from '@/plugins/env'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
Sentry.init({
  environment: getEnv('NODE_ENV'),
  dsn: getEnv('VUE_APP_SENTRY'),
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
      tracingOptions: {
        trackComponents: true
      },
      attachProps: true
    }),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1
})

Vue.config.productionTip = false
Vue.config.devtools = false
Vue.use(filterDate)
Vue.use(filterNumber)
Vue.use(filterString)
Vue.use(Vuelidate)
Vue.use(RirLib)

const initKeyCloak = () => {
  return new Promise(resolve => {
    Vue.use(VueKeyCloak, {
      init: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      config: {
        authRealm: getEnv('VUE_APP_KC_REALM'),
        authUrl: getEnv('VUE_APP_KC_AUTH'),
        authClientId: getEnv('VUE_APP_KC_CLIENT')
      },
      onReady: (keycloak) => {
        store.dispatch('session/getUserInfo')
          .then(() => {
            const role = store.getters['session/isPrevention'] || store.getters['session/isRoad']
            !role ? keycloak.logout() : resolve()
          })

        store.dispatch('directory/loadDirectories')
      }
    })
  })
}

const checkStartApp = (routeRoles) => {
  const isRoad = store.getters['session/isRoad']
  const isPrevention = store.getters['session/isPrevention']
  if (!routeRoles.some(el => el === isRoad || el === isPrevention)) {
    if (isRoad) {
      router.push({ name: 'road-list' })
    } else if (isPrevention) {
      router.push({ name: 'prevention-list' })
    } else {
      router.push({ name: 'error-page' })
    }
  }
}

export default new Vue({
  async mounted () {
    await initKeyCloak()
    this.$route.meta.roles && checkStartApp(this.$route.meta.roles)
    this.initSession = true
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  data: () => ({
    windowWidth: window.innerWidth,
    initSession: false
  }),
  router,
  store,
  // eslint-disable-next-line vue/require-render-return
  render (h) {
    if (this.initSession) {
      return h(App)
    }
  }
}).$mount('#app')
