<template>
  <section v-if="!isLoadData">
    <div class="row">
      <div class="col-md-12-12 d-flex align-center">
        <rir-icon icon="calendar" class="mr-xs-2"/>
        <span class="mr-xs-4 kleodora titanic--text biqe">{{ violationData.createdAt | dateTextMonth }}</span>
        <rir-icon icon="clock"  class="mr-xs-2"/>
        <span class="kleodora titanic--text biqe">{{ violationData.createdAt | time }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12-12 d-flex align-center">
        <span
          :class="[`${statusViolation.color}--text`]"
          class="kleodora">{{ statusViolation.name }}</span>
      </div>
      <div class="col-md-12-12 d-flex align-center">
        <rir-select
          v-if="isDepartmentHead"
          label="Инспектор"
          :items="profilesPrevention"
          id-value="id"
          text-value="fullName"
          v-model="model.executorId"
        />
        <template v-else>
          <rir-icon icon="avatar" class="mr-xs-2"/>
          <span class="kleodora titanic--text biqe">{{ violationData.executorName }}</span>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8-12 pl-xs-4">
        <h2 class="melia mb-xs-5">Информация о нарушении</h2>
        <violations-fields
          class="mx-xs-n7"
          v-model="model"
          :readonly="stepsLogic.isView"
          ref="fields"
        />
      </div>
      <div class="col-md-4-12 pr-xs-4">
        <h2 class="melia mb-xs-5">Фотографии нарушения</h2>
        <rir-file-list
          :count-line="1"
          :files="filesViolation"
          v-if="stepsLogic.isView"/>
        <rir-drag-upload-file
          v-else
          class="mb-xs-4"
          :count-file="0"
          :count-line="1"
          v-model="filesViolation"
          :upload-url="$root.$uploadServer"
          :params-body="{
            code: 'PREVENTION',
            type: '08-01'
          }"
          :accept-file="['.jpg', '.jpeg', '.png']"/>
      </div>
    </div>
    <div
      class="row"
      v-for="(component, index) in stepsLogic.components"
      :key="`comp-${index}`"
    >
      <div class="col-md-12-12">
        <component
          v-model="model"
          :is="component" />
      </div>
    </div>
    <div
      class="row"
       v-for="(action, index) in stepsLogic.actions"
      :key="`action-${index}`"
    >
      <div class="col-md-12-12">
        <rir-button
          :loading="isLoadUpdate"
          :color="action.colorAction"
          @click="action.funcAction">
          {{ action.textAction }}
        </rir-button>
      </div>
    </div>
  </section>
</template>

<script>
import mixinLayout from '@/layout/mixins/mixinLayout'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ViolationsFields from '@/components/fields/ViolationsFields'
import stepsLogic from '@/views/prevention/utils/stepsLogic'
import PrintPage from '@/plugins/mixins/PrintPage'

export default {
  name: 'prevention-item',
  components: { ViolationsFields },
  async mounted () {
    this.setViolationSourceCode('PREVENTION')
    await this.loadData()
    this.changeTitlePage(`Нарушение № ${this.violationData.number}`)
  },
  destroyed () {
    this.clearModel('violationData')
    this.clearModel('violationModel')
  },
  mixins: [mixinLayout, stepsLogic, PrintPage],
  data: () => ({
    isLoadData: true,
    isLoadUpdate: false,
    model: null
  }),
  props: {
    violationId: {
      type: String
    }
  },
  computed: {
    ...mapState('violation', ['violationModel']),
    ...mapState('directory', ['statuses', 'profilesPrevention']),
    ...mapGetters('session', ['isPrevention']),
    isDepartmentHead () {
      return ['PREV_DEPARTMENT_HEAD'].includes(this.isPrevention) &&
        !['DIRECTED_TO_MUNICIPAL_CONTROL', 'ELIMINATED', 'CANCELLED_DRAFT']
          .includes(this.violationData.status)
    },
    statusViolation () {
      return this.statuses.find(s => s.code === this.violationData.status)
    },
    filesViolation: {
      get () {
        return this.model.attachments.filter(attach => attach.type === '08-01')
      },
      set (val) {
        const model = { ...this.model }
        const attach = model.attachments.filter(attach => attach.type !== '08-01')
        this.model.attachments = attach.concat(val)
      }
    }
  },
  methods: {
    ...mapMutations('violation', ['setViolationSourceCode', 'clearModel']),
    ...mapActions('violation', ['fetchViolationItem', 'updateViolation']),
    ...mapActions('directory', ['updateExecutor']),
    async loadData () {
      this.isLoadData = true
      if (this.violationId !== this.violationData.id) {
        try {
          await this.fetchViolationItem(this.violationId)
        } catch (e) {
          switch (e.response.status) {
            case 403:
              await this.$router.push({ name: 'prevention-list' })
              break
          }
          return
        }
      }
      this.model = { ...this.violationModel }
      this.isLoadData = false
    },
    async updateItem () {
      this.$refs.fields.$v.$touch()
      if (this.$refs.fields.$v.$invalid) return false
      this.isLoadUpdate = true
      if (this.isDepartmentHead &&
        this.violationModel.executorId !== this.model.executorId) {
        await this.updateExecutor({
          executorId: this.model.executorId,
          id: this.violationId
        })
      }
      await this.updateViolation({
        model: this.model
      })
      this.isLoadUpdate = false
      return true
    }
  }
}
</script>
