export default {
  async fetchViolationList ({ rootGetters, getters, commit }, payload) {
    const data = await rootGetters.GET(`violation/${getters.violationSourceCode}`, payload)
    if (payload.pageIndex === 0) {
      commit('setProperty', { name: 'violations', value: data })
    } else {
      commit('pushList', data)
    }
  },
  async fetchViolationItem ({ rootGetters, getters, commit }, guid) {
    const data = await rootGetters.GET(`violation/${getters.violationSourceCode}/${guid}`)
    commit('saveDataModelInResponse', {
      modelState: 'violationData',
      data
    })
    commit('saveDataModelInResponse', {
      modelState: 'violationModel',
      data
    })
    data?.task && commit('saveDataModelInResponse', {
      modelState: 'task',
      data: data.task
    })
    data?.act1 && commit('saveDataModelInResponse', {
      modelState: 'act1',
      data: data.act1
    })
    data?.act2 && commit('saveDataModelInResponse', {
      modelState: 'act2',
      data: data.act2
    })
  },
  async addViolation ({ rootGetters, getters, commit }, payload) {
    const { model } = payload
    const { id } = await rootGetters.POST(`violation/${getters.violationSourceCode}`, model)
    // commit('unshiftList', { ...model, id, status: 'CREATING_DRAFT' })
    return { id }
  },
  async updateViolation ({ rootGetters, getters, state, commit }, payload) {
    const { model } = payload
    const data = await rootGetters.PUT(
      `violation/${getters.violationSourceCode}/${state.violationData.id}`,
      model
    )
    commit('saveDataModelInResponse', {
      modelState: 'violationModel',
      data
    })
  },
  async changeStatusViolation ({ rootGetters, state, commit, getters }, {
    decision = null,
    note = null
  }) {
    const model = {
      id: state.violationData.id,
      statusCode: state.violationData.status,
      decision,
      note
    }
    const { message } = await rootGetters.POST(`violation/${getters.violationSourceCode}/update-status`, model)
    commit('changeViolationStatus', message)
  },
  async changeDateViolation ({ rootGetters, state, commit, getters }, { date }) {
    await rootGetters.PUT(`violation/${getters.violationSourceCode}/${state.violationData.id}/execution-term`, {
      value: date
    })
    commit('changeViolationExecutionTerm', date)
  },
  async changeFavoriteItemList ({ rootGetters, getters }, payload) {
    const { id, value } = payload
    await rootGetters.PUT(`violation/${getters.violationSourceCode}/${id}/favourite`, {
      value
    })
  },
  async changeAttachmentsViolations ({ rootGetters, getters, state, commit }, payload) {
    const { attachmentsList } = payload
    await rootGetters.PUT(
      `violation/${getters.violationSourceCode}/${state.violationData.id}/attachment`,
      attachmentsList
    )
    commit('updateViolationAttachments', payload)
  },
  async changeSelectAllDoc ({ rootGetters, getters, state }, payload) {
    const { value } = payload
    // /api/v1/violation/road-cover-inspection/{id}/docs-uploaded
    await rootGetters.PUT(
      `violation/${getters.violationSourceCode}/${state.violationData.id}/docs-uploaded`,
      {
        value
      }
    )
  }
}
