import { mapState, mapActions, mapGetters } from 'vuex'
import SigningTaskWithBoss from '@/views/road/steps/SigningTaskWithBoss/index'
import SigningWarningWithDirector from '@/views/road/steps/SigningWarningWithDirector/index'
import WaitingViolator from '@/views/road/steps/WaitingViolator/index'
import EliminationViolation from '@/views/road/steps/EliminationViolation/index'
import MessageGibdd from '@/views/road/steps/MessageGibdd/index'
import SigningCautionByDepartmentDirector from '@/views/road/steps/SigningCautionByDepartmentDirector/SigningCautionByDepartmentDirector'
import PreparationForArchive from '@/views/road/steps/PreparationForArchive/PreparationForArchive'

export default {
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    stepsLogic () {
      const isEmail = this.violationData.emailSendingStatus?.code !== 'NOT_SENT' ? WaitingViolator : null
      const isSpecialist = [
        'RCI_SPECIALIST'
      ].includes(this.isRoad)
      // @TODO Переработать логику сбора компонентов, что бы избавиться от перерендоринга
      let steps = null
      switch (this.violationData.status) {
        case 'CREATING_TASK':
          steps = {
            isView: [
              'VICE_MAYOR'
            ].includes(this.isRoad),
            colorStatus: 'titanic--text',
            actions: isSpecialist ? [
              {
                action: this.updateItem,
                color: 'primary',
                text: 'Сохранить'
              },
              {
                action: async () => {
                  if (await this.updateItem()) {
                    const confirm = await window.confirm('Действительно хотите отменить нарушений?')
                    confirm && await this.changeStatusViolation({ decision: 'CANCEL' })
                    this.$router.push('/road')
                  }
                },
                color: 'error',
                text: 'Отменить'
              }
            ] : [
              {
                action: this.updateItem,
                color: 'primary',
                text: 'Сохранить'
              }
            ],
            components: [
              SigningTaskWithBoss
            ]
          }
          break
        case 'FIXING_VIOLATION':
        case 'RETURNED_FROM_SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          steps = {
            isView: [
              'VICE_MAYOR'
            ].includes(this.isRoad),
            actions: [
              {
                action: this.updateItem,
                color: 'primary',
                text: 'Сохранить'
              }
            ],
            components: [
              SigningTaskWithBoss,
              SigningWarningWithDirector
            ]
          }
          break
        case 'SIGNING_CAUTION_BY_DEPARTMENT_DIRECTOR':
          steps = {
            isView: [
              'VICE_MAYOR'
            ].includes(this.isRoad),
            actions: [
              {
                action: this.updateItem,
                color: 'primary',
                text: 'Сохранить'
              }
            ],
            components: [
              SigningTaskWithBoss,
              SigningWarningWithDirector,
              SigningCautionByDepartmentDirector
            ]
          }
          break
        case 'MAKING_SENDING_ERROR_DECISION':
        case 'RECEIVING_OWNER_RESPONSE':
        case 'SENDING_CAUTION_TO_VIOLATOR':
          steps = {
            isView: true,
            components: [
              SigningTaskWithBoss,
              SigningWarningWithDirector,
              SigningCautionByDepartmentDirector,
              isEmail
            ]
          }
          break
        case 'INSPECTING_ELIMINATION_VIOLATION':
          steps = {
            isView: true,
            components: [
              SigningTaskWithBoss,
              SigningWarningWithDirector,
              SigningCautionByDepartmentDirector,
              isEmail,
              EliminationViolation
            ]
          }
          break
        case 'PREPARATION_FOR_ARCHIVE':
        case 'ELIMINATED':
          steps = {
            isView: true,
            components: [
              SigningTaskWithBoss,
              SigningWarningWithDirector,
              SigningCautionByDepartmentDirector,
              isEmail,
              EliminationViolation,
              PreparationForArchive
            ]
          }
          break
        case 'DIRECTING_TO_GIBDD':
        case 'DIRECTED_TO_GIBDD':
          steps = {
            isView: true,
            components: [
              SigningTaskWithBoss,
              SigningWarningWithDirector,
              SigningCautionByDepartmentDirector,
              isEmail,
              EliminationViolation,
              MessageGibdd
            ]
          }
          break
        default:
          steps = {
            isView: true,
            colorStatus: 'titanic--text'
          }
          break
      }
      steps = { ...steps, components: steps.components.filter(el => !!el) }
      return steps
    }
  },
  methods: {
    ...mapActions('violation', ['changeStatusViolation'])
  }
}
