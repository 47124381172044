<template>
  <section class="d-flex flex-column">
    <generate-task v-if="isForm"/>
    <div class="d-flex align-center">
      <rir-icon
        :fill="statusType.iconColor"
        class="mr-xs-2"
        :icon="statusType.icon"
      />
      <span>{{ statusType.status }}</span>
    </div>
    <span class="pl-xs-6 titanic--text anie">{{ statusType.text }}</span>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GenerateTask from '@/views/road/steps/EliminationViolation/GenerateTask'

export default {
  name: 'status-task',
  components: { GenerateTask },
  computed: {
    ...mapState('violation', ['violationData']),
    ...mapGetters('session', ['isRoad']),
    statusType () {
      switch (this.violationData.status) {
        case 'INSPECTING_ELIMINATION_VIOLATION':
          return {
            iconColor: 'rocky',
            icon: 'in-progress',
            status: 'В процессе',
            text: 'Ожидание пакета документов'
          }
        default:
          return {
            iconColor: 'matrix',
            icon: 'selected',
            status: 'Проверка проведена',
            text: 'Решение принято'
          }
      }
    },
    isForm () {
      return ['RCI_SPECIALIST'].includes(this.isRoad)
    }
  }
}
</script>
