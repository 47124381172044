<template>
  <rir-cards-block>
    <div slot="title" class="nomira titanic--text px-8">Добавить документы</div>
    <section
      class="d-flex flex-column px-8"
      v-for="(attach, index) in attachments"
      :key="attach.uuid"
    >
      <div class="d-flex align-center mb-6">
        <rir-form-item
          class="flex-auto"
          :error="$v.attachments.$each[index].type.$error"
          :message="mesErr($v.attachments.$each[index].type)"
        >
          <rir-select
            :items="typesAttach"
            id-value="code"
            text-value="name"
            label="Тип документа"
            v-model="attach.type"
            @change="clearFiles(index)"
          />
        </rir-form-item>
        <rir-button
          class="ml-2"
          v-if="attachments.length > 1"
          transparent
          @click="deleteAttach(index)"
          icon="delete"
          color="error" />
      </div>
      <rir-form-item
        v-if="attach.type"
        class="mb-6"
        :error="$v.attachments.$each[index].files.$error"
        :message="mesErr($v.attachments.$each[index].files)"
      >
        <rir-drag-upload-file
        :count-file="1"
        :count-line="1"
        :upload-url="$root.$uploadServer"
        :params-body="{
          code: 'ROAD_COVER_INSPECTION',
          type: attach.type
        }"
        :accept-file="['.odt', '.docx', '.doc', '.jpg', '.jpeg', '.png', '.pdf']"
        v-model="attach.files"/>
      </rir-form-item>
    </section>
    <div
      class="d-flex flex-column px-8"
      slot="actions">
      <rir-button
        :disabled="isMaxCountField"
        :loading="isUpdate"
        @click="addAttach"
        class="mb-4"
        color="secondary">
        Добавить другой тип документа
      </rir-button>
      <rir-button
        :loading="isUpdate"
        @click="saveAttach"
      >
        Сохранить
      </rir-button>
    </div>
  </rir-cards-block>
</template>

<script>

import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'update-package-doc',
  mixins: [validationMixin, validateMixin],
  data: (vm) => ({
    isUpdate: false,
    attachments: [
      {
        uuid: vm.uuIdv4(),
        type: null,
        files: []
      }
    ]
  }),
  validations: {
    attachments: {
      $each: {
        type: {
          required: required('Обязательное поле')
        },
        files: {
          required: required('Обязательное поле')
        }
      }
    }
  },
  computed: {
    ...mapState('violation', ['violationModel']),
    typesAttach () {
      // '09-01', '09-02', '09-03', '09-04', '09-05', '09-06', '09-07'
      let type = [
        {
          code: '09-01',
          name: 'Задание',
          disabled: false
        },
        {
          code: '09-02',
          name: 'Акт 1',
          disabled: false
        },
        {
          code: '09-03',
          name: 'Фототаблица 1',
          disabled: false
        },
        {
          code: '09-04',
          name: 'Предостережение',
          disabled: false
        },
        {
          code: '09-05',
          name: 'Акт 2',
          disabled: false
        },
        {
          code: '09-06',
          name: 'Фототаблица 2',
          disabled: false
        },
        {
          code: '09-07',
          name: 'Мотивированное представление',
          disabled: false
        }
      ]
      type = type.map((t) => {
        return {
          ...t,
          disabled: this.selectType.includes(t.code)
        }
      })
      return type
    },
    isMaxCountField () {
      return this.typesAttach.length === this.attachments.length
    },
    selectType () {
      return this.attachments.map(a => a.type)
    }
  },
  methods: {
    ...mapActions('violation', ['changeAttachmentsViolations']),
    addAttach () {
      this.attachments.push({
        uuid: this.uuIdv4(),
        type: null,
        files: []
      })
    },
    deleteAttach (index) {
      this.attachments.splice(index, 1)
    },
    async saveAttach () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notification({
          type: 'error',
          text: 'Требуется заполнить все поля'
        })
        return
      }
      this.isUpdate = true
      const mapArchive = new Map([
        ['09-01', '09-18'],
        ['09-02', '09-19'],
        ['09-03', '09-21'],
        ['09-04', '09-23'],
        ['09-05', '09-20'],
        ['09-06', '09-22'],
        ['09-07', '09-24']
      ])
      let attachmentsList = this.violationModel.attachments.map(a => {
        if (this.selectType.includes(a.type)) {
          a.type = mapArchive.get(a.type)
        }
        return a
      })
      this.attachments.forEach(a => {
        attachmentsList = attachmentsList.concat(a.files)
      })
      await this.changeAttachmentsViolations({ attachmentsList })

      this.$attrs.closeModal()
      this.$notification({
        type: 'success',
        text: 'Документы обновлены'
      })
      this.isUpdate = false
    },
    clearFiles (index) {
      this.attachments[index].files = []
    }
  }
}
</script>
