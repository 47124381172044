import Entity from '@/models/Entity'
import getEnv from '@/plugins/env'

export default class rViolatorEntity extends Entity {
  id = null
  fullName = null
  email = null
  phone = null
  legalName = null

  static config = {
    formFields: [
      {
        title: 'ФИО',
        column: 'fullName',
        validate: []
      },
      {
        title: 'Email',
        column: 'email',
        validate: [
          {
            type: 'require',
            text: 'Обязательное поле'
          },
          {
            type: 'regEx',
            re: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            text: 'Не верный формат Email'
          }
        ]
      },
      {
        title: 'Телефон',
        column: 'phone',
        mask: '+7 (###) ###-##-##',
        validate: [
          {
            type: 'require',
            text: 'Обязательное поле'
          },
          {
            type: 'regEx',
            re: /^\+7[-. ]?\(([0-9]{3})\)?[-. ]?([0-9]{3})[-]?([0-9]{2})[-]?([0-9]{2})$/,
            text: 'Формат телефона +X (XXX) XXX-XX-XX'
          }
        ]
      },
      {
        title: 'Название организации',
        column: 'legalName',
        validate: [
          {
            type: 'require',
            text: 'Обязательное поле'
          }
        ]
      }
    ],
    link: `${getEnv('VUE_APP_API_SERVER')}violation/ref/road-cover-inspection/violator/`
  }

  constructor (data = {}) {
    super()
    this.setDataEntity(data)
  }

  static getClassName () { return 'rViolatorEntity' }
  getClassName () { return rViolatorEntity.getClassName() }
}
